import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BehaviorSubject,
	combineLatest,
	firstValueFrom,
	Observable,
	of,
	Subject,
	timer,
} from 'rxjs';
import {
	distinctUntilChanged,
	filter,
	first,
	map,
	mergeMap,
	switchMap,
	take,
	takeUntil,
} from 'rxjs/operators';
import {
	currentSelectedProject,
	selectFilesInFilesSection,
	selectProjectFileById,
} from '@store/selectors/project-files.selectors';
import { CompanyFile, File as FileEntity, FileType } from 'domain-entities';
import { find, last, orderBy, values } from 'lodash';
import { BreadcrumbUI } from '@craftnote/shared-angular-modules';
import { selectCompanyId, selectProfile, selectUserId } from '@store/selectors/app.selectors';
import { FILE_TYPE, FileDocument, shareReplayOne } from '@craftnote/shared-utils';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileExplorerService } from '@injectables/services/file-explorer/file-explorer.service';
import { ShrinkOutAnimation } from '@shared/animations/shrink-out-animation';
import { RotateAnimation } from '@shared/animations/rotate-animation';
import { NewFolderDialogComponent } from '@work/file-explorer/modals/new-folder-dialog/new-folder-dialog.component';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { FILE_TYPES } from '@work/file-explorer/file-types';
import { CopyToOtherProjectDialogComponent } from '../copy-to-other-project-dialog/copy-to-other-project-dialog.component';
import { AlertService } from '@injectables/services/alert/alert.service';
import { BaseFileService } from '@injectables/services/base-file.service';
import { CompanyTemplate } from '@shared/models/company-template.model';
import { selectChatMessageToShare } from '@modules/features/chat/store/selectors/message.selectors';
import { clearSelectedChatMessageToShareAction } from '@modules/features/chat/store/actions/message.actions';
import { DeleteProjectFilesConfirmationDialogComponent } from '../delete-project-files-confirmation-dialog/delete-project-files-confirmation-dialog.component';
import {
	FileSectionOperation,
	ProjectFile,
	ProjectFilesSortOptions,
	ProjectFileViewOptions,
	ProjectParentType,
} from '@shared/models/project-file.model';
import { selectAllCompanyFiles } from '@store/selectors/company-files.selectors';
import { initProjectFilesStateChangesAction } from '@store/actions/project-files.actions';
import { fadeInOutAnimation } from '@shared/animations/fade-in-out-animation';
import { TranslateService } from '@ngx-translate/core';
import { selectActiveProject } from '@store/selectors/route.selectors';
import {
	FilesFileCopiedEventBuilder,
	FilesFileDeletedEventBuilder,
	FilesFileMovedEventBuilder,
} from '@generated/events/FilesEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	TemplatesHintClosedEventBuilder,
	TemplatesHintCtaOpenedEventBuilder,
	TemplatesOnboardingClosedEventBuilder,
} from '@generated/events/TemplatesEvents.generated';
import { CreationExport, ExportService } from '@injectables/export/export.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupDialogService } from '@injectables/services/popup-dialog.service';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { FolderUploadService } from '@injectables/services/file-explorer/folder-upload.service';
import { ErrorHandlerService } from '@injectables/services/errors/error-handler.service';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { BasicUserSubscriptionDialogComponent } from '@modules/features/dashboard/components/basic-user-subscription-dialog/basic-user-subscription-dialog.component';
import { selectUserProfileLimitsSelector } from '@store/selectors/profile-limits.selectors';

@Component({
	selector: 'app-files-section',
	templateUrl: './files-section.component.html',
	styleUrls: ['./files-section.component.scss'],
	animations: [ShrinkOutAnimation, RotateAnimation, fadeInOutAnimation()],
})
export class FilesSectionComponent implements OnInit, OnDestroy {
	private allProjectFilesFolders: ProjectFile[] = [];
	private destroy$ = new Subject();
	private companyFolderName: string = this.translateService.instant(
		'files-section.company-templates',
	);
	@ViewChild('uploadForm', { read: ElementRef }) uploadForm: ElementRef<HTMLFormElement>;
	readonly FILE_TYPE = FileType;
	readonly ACCEPTED_FILE_TYPES = FILE_TYPES;
	selectedFiles: { [id: string]: ProjectFile } = {};
	isDragStarted = false;
	currentFileSelectionOperation$ = new BehaviorSubject<FileSectionOperation>(null);
	filesToCopyOrMove: ProjectFile[] = [];
	sourceFolder: string = null;
	createButtonsActive = false;
	viewOption: ProjectFileViewOptions = 'list';
	selectedProjectId$ = this.store.select(selectActiveProject);
	sortOptions$ = new BehaviorSubject<ProjectFilesSortOptions>({
		sortType: 'name',
		sortOrder: 'asc',
	});
	currentSelectedFolder$ = new BehaviorSubject<ProjectParentType>(null);
	selectFilesInFilesSection$ = this.store.select(selectFilesInFilesSection).pipe(
		map((selectedFiles) => {
			if (!selectedFiles) {
				return selectedFiles;
			}

			return selectedFiles.map((file) => {
				if (file.id === 'company-folder') {
					file.name = this.companyFolderName;
					return file;
				}

				return file;
			});
		}),
		shareReplayOne(),
	);
	isCompanyFolder$ = combineLatest([
		this.currentSelectedFolder$,
		this.selectFilesInFilesSection$,
	]).pipe(
		map(([selectedFolderId, files]) => {
			if (selectedFolderId === 'company-folder') {
				return true;
			}

			return Boolean(
				files.find((file) => file.fileCategory === 'company-file' && file.id === selectedFolderId),
			);
		}),
	);
	chatMessageToShare$ = this.store.select(selectChatMessageToShare);
	filesAndFoldersInCurrentSelected$ = combineLatest([
		this.selectFilesInFilesSection$,
		this.currentSelectedFolder$,
		this.currentFileSelectionOperation$,
	]).pipe(
		map(([projectFiles, selectedFolder, filesOperation]) => {
			const filteredProjects = projectFiles.filter((file) => file.parentId === selectedFolder);
			return filesOperation
				? filteredProjects.filter(
					(file) =>
						file.fileType === FileType.FOLDER &&
						file.fileCategory !== 'company-file' &&
						!find(this.filesToCopyOrMove, { id: file.id }),
				)
				: filteredProjects;
		}),
		shareReplayOne(),
	);
	projectFilesFolders$ = this.filesAndFoldersInCurrentSelected$.pipe(
		map((files) => files.filter((file) => file.fileCategory !== 'company-file')),
	);
	selectProjectFiles$ = combineLatest([
		this.filesAndFoldersInCurrentSelected$,
		this.sortOptions$,
	]).pipe(
		mergeMap(async ([currentFolderFiles, sortOptions]) => {
			let folders = currentFolderFiles.filter((file) => file.fileType === FileType.FOLDER);
			let files = currentFolderFiles.filter((file) => file.fileType !== FileType.FOLDER);

			[folders, files] = await Promise.all([
				this.sortBy(sortOptions, folders),
				this.sortBy(sortOptions, files),
			]);

			return [...folders, ...files];
		}),
		shareReplayOne(),
	);
	isUploadFilesMenuEnabled$ = combineLatest([
		this.selectFilesInFilesSection$,
		this.currentSelectedFolder$,
	]).pipe(
		map(([selectedFiles, currentSelectedFolder]) => {
			if (!currentSelectedFolder) {
				return true;
			}

			const selectedFolder = selectedFiles.find((file) => file.id === currentSelectedFolder);
			return !!selectedFolder && selectedFolder.fileCategory !== 'company-file';
		}),
	);
	templatesPromotionalContentDismissed$ = new BehaviorSubject(true);
	templatesPromotionalContentVisible$ = this.initTemplatesPromotionalContentVisibleStream();
	templatesHintDismissed$ = new BehaviorSubject(true);
	templatesHintVisible$ = this.initTemplatesHintVisibleStream();

	constructor(
		private readonly store: Store,
		private readonly dialog: MatDialog,
		private readonly fileService: FileExplorerService,
		private readonly baseFileService: BaseFileService,
		private readonly alertService: AlertService,
		private readonly localStorageService: LocalStorageService,
		private readonly translateService: TranslateService,
		private readonly trackingService: TrackingService,
		private readonly exportService: ExportService,
		private readonly popupDialogService: PopupDialogService,
		private readonly errorHandlerService: ErrorHandlerService,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly folderUploadService: FolderUploadService,
		private readonly notificationService: NotificationSnackbarService,
	) {
		this.initProjectFilesSubscription();
		this.initProjectChangeSubscription();
		this.initChatMessageToShareSubscription();
	}

	private _folderBreadcrumbs: Array<ProjectFile> = [];

	get folderBreadcrumbs(): Array<BreadcrumbUI> {
		return this._folderBreadcrumbs.map((path) => {
			return {
				name: path.name,
				id: path.id,
				readOnly: false,
				hasChildren: true,
			} as BreadcrumbUI;
		});
	}

	get selectedFilesLength(): number {
		return Object.keys(this.selectedFiles).length;
	}

	get isOneOfFolderSelected(): boolean {
		return Object.values(this.selectedFiles).some((file) => file.fileType === FileType.FOLDER);
	}

	get isDragAndDropEnabled$(): Observable<boolean> {
		if (this.selectedFilesLength || this.filesToCopyOrMove.length) {
			return of(false);
		}

		return this.isCompanyFolder$.pipe(
			take(1),
			map((isCompanyFolder) => {
				return !isCompanyFolder;
			}),
		);
	}

	ngOnInit(): void {
		this.initAllProjectFilesFolders();
		this.listenOpenUrlParam();
	}

	checkFileType = (file: ProjectFile, fileType: FileType): boolean => {
		return (
			file.fileType === fileType &&
			(fileType !== FileType.DOCUMENT || this.baseFileService.getFileExtension(file.name) === 'pdf')
		);
	};

	getCurrentFolderFilesByFileType(
		fileType: FileType,
	): Observable<Array<CompanyTemplate | FileDocument>> {
		return this.selectProjectFiles$.pipe(
			map((files) =>
				files
					.filter((currentFile) => this.checkFileType(currentFile, fileType))
					.map((currentFile) => {
						if (currentFile.fileCategory === 'company-file') {
							return new CompanyTemplate().deserialize(currentFile.base);
						} else {
							return new FileDocument().deserialize(currentFile.base);
						}
					}),
			),
		);
	}

	async download(file: ProjectFile): Promise<void> {
		const canDownloadMultipleFiles = await firstValueFrom(this.store
			.select(selectUserProfileLimitsSelector)
			.pipe(map((profileLimits) => !profileLimits || profileLimits.filesMultiDownload)));

		const isMultipleFiles = this.selectedFilesLength > 1 || file.fileType === this.FILE_TYPE.FOLDER;
		if (!canDownloadMultipleFiles && isMultipleFiles) {
			this.openBasicUserSubscriptionDialog();
			return;
		}

		const projectId = await this.selectedProjectId$.pipe(take(1)).toPromise();
		if (isMultipleFiles) {
			await this.exportMultipleFiles(projectId, file);
		} else {
			await this.exportSingleFile(file, projectId);
		}
	}

	async moveFilesOnDrop(dropFileId: string | null): Promise<void> {
		if (!this.isDragStarted) {
			return;
		}

		this.filesToCopyOrMove = Object.keys(this.selectedFiles).map((fileId) =>
			find(this.allProjectFilesFolders, { id: fileId }),
		);
		this.sourceFolder = dropFileId;
		this.selectedFiles = {};

		await this.moveFiles(dropFileId);
	}

	dragStartedEnded(isDragStarted: boolean): void {
		this.isDragStarted = isDragStarted;
	}

	toggleFileSelection(file: ProjectFile): void {
		if (this.selectedFiles[file.id]) {
			delete this.selectedFiles[file.id];
		} else {
			this.selectedFiles[file.id] = file;
		}
	}

	setViewOption(viewOption: ProjectFileViewOptions): void {
		this.viewOption = viewOption;
	}

	setSort(type: 'name' | 'createdTimestamp', order: 'asc' | 'desc'): void {
		const sortOptions = {
			sortType: type,
			sortOrder: order,
		};
		this.sortOptions$.next(sortOptions);
	}

	goBack(): void {
		this._folderBreadcrumbs.pop();
		const currentPath = this._folderBreadcrumbs.length ? last(this._folderBreadcrumbs).id : null;
		this.currentSelectedFolder$.next(currentPath);
	}

	goToRoot(): void {
		this._folderBreadcrumbs = [];
		this.currentSelectedFolder$.next(null);
	}

	openFolder(folder: ProjectFile): void {
		if (this.selectedFilesLength) {
			return;
		}

		this._folderBreadcrumbs.push(folder);
		this.currentSelectedFolder$.next(folder.id);
		const currentSelectedFolderId = this.currentSelectedFolder$.getValue();
		const currentSelectedFolderIndex = this._folderBreadcrumbs.findIndex(
			(folderBreadcrumb) => folderBreadcrumb.id === currentSelectedFolderId,
		);

		this._folderBreadcrumbs = this._folderBreadcrumbs.filter(
			(folderBreadcrumb) =>
				this._folderBreadcrumbs.indexOf(folderBreadcrumb) <= currentSelectedFolderIndex,
		);
	}

	selectFolder(folder: ProjectFile): void {
		if (this.selectedFilesLength) {
			return;
		}

		if (!this._folderBreadcrumbs.includes(folder)) {
			this._folderBreadcrumbs.push(folder);
		}
		this.currentSelectedFolder$.next(folder.id);
	}

	async openFile(file: ProjectFile): Promise<void> {
		if (this.selectedFilesLength) {
			return;
		}

		const [projectId, currentFolderFiles] = await combineLatest([
			this.selectedProjectId$,
			this.getCurrentFolderFilesByFileType(file.fileType),
			this.store.select(selectCompanyId),
		])
			.pipe(take(1))
			.toPromise();

		if (
			file.fileType === FileType.DOCUMENT &&
			this.baseFileService.getFileExtension(file.name) !== 'pdf'
		) {
			const fileLink = await this.fileService.getFile(projectId, file.base, false);

			this.baseFileService.downloadFile(fileLink, file.name);
			return;
		}
		const index = currentFolderFiles.findIndex((currentFile) => currentFile.id === file.id);
		this.popupDialogService.openPopupDialog(currentFolderFiles, index, true);
	}

	toggleActionMenu(): void {
		this.createButtonsActive = !this.createButtonsActive;
	}

	cancelSelection(): void {
		if (this.selectedFilesLength > 0) {
			this.selectedFiles = {};
		}
	}

	async deleteSelectedFilesAndFolders(file: ProjectFile): Promise<void> {
		let files: { [id: string]: ProjectFile };
		if (this.selectedFilesLength === 0 && file) {
			files = { [file.id]: file };
		} else {
			files = this.selectedFiles;
		}
		const [currentProjectId, allFiles] = await combineLatest([
			this.selectedProjectId$,
			this.selectFilesInFilesSection$.pipe(
				map((projectFiles) => {
					return projectFiles
						.filter(
							(projectFile) => projectFile.fileCategory !== 'company-file' && !!projectFile.base,
						)
						.map((projectFile) => new FileDocument().deserialize(projectFile.base));
				}),
			),
		])
			.pipe(take(1))
			.toPromise();

		const selectedFiles = await this.getSelectedFilesWithCounts(files);
		const deleteDialog = this.dialog.open(DeleteProjectFilesConfirmationDialogComponent, {
			data: { selectedFiles },
			width: '500px',
			autoFocus: false,
		});
		const confirmation = await deleteDialog.afterClosed().pipe(take(1)).toPromise();

		if (!confirmation) {
			return;
		}

		await this.fileService.deleteMultipleFiles(
			selectedFiles.map((projectFile) => new FileDocument().deserialize(projectFile.file.base)),
			currentProjectId,
			allFiles,
		);

		await Promise.all(
			selectedFiles.map((projectFile) =>
				this.trackingService.trackEvent(
					new FilesFileDeletedEventBuilder({
						fileId: projectFile.file.id,
						projectId: currentProjectId,
					}),
				),
			),
		);

		this.alertService.showAlert('files-section.messages.delete.success');
		this.cancelSelection();
	}

	async getSelectedFilesWithCounts(files: {
		[id: string]: ProjectFile;
	}): Promise<{ file: ProjectFile; fileCount?: number; subFolderCount?: number }[]> {
		return Object.keys(files).map((fileId) => {
			const projectFile = this.allProjectFilesFolders.find((file) => file.id === fileId);
			if (!projectFile) {
				return;
			}
			if (projectFile.fileType === FileType.FOLDER) {
				const subFolderCount = this.countSubFolders(projectFile.id);
				return { file: projectFile, fileCount: projectFile.noOfFiles, subFolderCount };
			} else {
				return { file: projectFile };
			}
		});
	}

	closeActionMenu(): void {
		this.createButtonsActive = false;
	}

	async copyOrMoveWithInTheProject(
		fileOperation: FileSectionOperation,
		file: ProjectFile,
	): Promise<void> {
		this.filesToCopyOrMove = await this.getSelectedFiles(file);
		this.sourceFolder = await this.currentSelectedFolder$.pipe(take(1)).toPromise();
		this.currentFileSelectionOperation$.next(fileOperation);
		this._folderBreadcrumbs = [];
		this.selectedFiles = {};
		this.currentSelectedFolder$.next(null);
	}

	async copyFilesToOtherProject(file?: ProjectFile): Promise<void> {
		const filesToCopy = await this.getSelectedFiles(file);
		const confirmation = await this.dialog
			.open(CopyToOtherProjectDialogComponent, {
				width: '500px',
				data: {
					selectedFiles: filesToCopy,
					operation: 'files-section',
				},
				autoFocus: false,
				disableClose: true,
			})
			.afterClosed()
			.pipe(take(1))
			.toPromise();

		if (!confirmation) {
			return;
		}

		this.alertService.showAlert('files-section.messages.success');
	}

	async copyToChat(file: ProjectFile): Promise<void> {
		const filesToCopy = await this.getSelectedFiles(file);
		await this.copyFilesToChat(filesToCopy);
		await this.selectedProjectId$.pipe(first()).toPromise();
	}

	async copyMultipleToChat(file: ProjectFile): Promise<void> {
		const filesToCopy = await this.getSelectedFiles(file);
		await this.copyFilesToChat(filesToCopy);
	}

	async copyToChatInOtherProject(file: ProjectFile): Promise<void> {
		const filesToCopy = await this.getSelectedFiles(file);
		await this.copyFilesToChatInOtherProject(filesToCopy);
	}

	async copyFilesToChat(filesToCopy: ProjectFile[]): Promise<void> {
		const [currentProjectId, profile] = await combineLatest([
			this.selectedProjectId$,
			this.store.select(selectProfile),
		])
			.pipe(take(1))
			.toPromise();

		await this.fileService.uploadFilesToChat(
			filesToCopy.map((projectFile) => projectFile.base as FileEntity),
			profile,
			currentProjectId,
		);
		this.alertService.showAlert('files-section.messages.copy-to-chat.success');
		this.selectedFiles = {};
	}

	async uploadFiles(files: DataTransfer | FileList): Promise<void> {
		if (files instanceof DataTransfer) {
			const isAllowed = await firstValueFrom(this.isDragAndDropEnabled$.pipe(take(1)));
			if (!isAllowed) {
				return;
			}
		}

		this.closeActionMenu();

		const [currentSelectedFolder, currentProject] = await combineLatest([
			this.currentSelectedFolder$.pipe(
				mergeMap((folderId) => this.store.select(selectProjectFileById, { id: folderId })),
			),
			this.store.select(currentSelectedProject),
		])
			.pipe(take(1))
			.toPromise();

		const currentRoot = new FileDocument().deserialize(currentSelectedFolder || {});
		try {
			await this.folderUploadService.uploadFolder(files, currentProject.id, currentRoot.id);
			this.notificationService.show(BasicSnackbarComponent, {
				componentTypes: {
					description: this.translateService.instant('files-section.messages.file-upload.success'),
					icon: 'done',
				},
			});
		} catch (error) {
			this.errorHandlerService.handleError('FILE_UPLOAD: File could not be uploaded');
		}
		this.uploadForm.nativeElement.reset();
	}

	async openNewFolderDialog(): Promise<void> {
		const dialogRef = this.dialog.open(NewFolderDialogComponent, {
			width: '500px',
		});
		const res = await dialogRef.afterClosed().pipe(take(1)).toPromise();

		if (res) {
			await this.addFolder({ name: res });
			this.closeActionMenu();
		}
	}

	async selectAll(): Promise<void> {
		const currentFilesAndFolders = await this.filesAndFoldersInCurrentSelected$
			.pipe(take(1))
			.toPromise();
		currentFilesAndFolders.forEach((file) => {
			if (file.fileCategory !== 'company-file') {
				this.selectedFiles[file.id] = file;
			}
		});
	}

	async confirmCopyOrMove(isConfirmed: boolean): Promise<void> {
		if (!isConfirmed) {
			this.clearFilesSelection();
			return;
		}

		const fileOperation = this.currentFileSelectionOperation$.getValue();

		switch (fileOperation) {
			case 'copy-chat-file':
				await this.copyChatFileToFolder();
				break;
			case 'copy-with-in-project':
				await this.confirmCopyWithInProject();
				break;
			case 'move':
				await this.confirmMove();
				break;
		}
	}

	async onClickTemplatesHintHideButton(): Promise<void> {
		await this.trackingService.trackEvent(new TemplatesHintClosedEventBuilder({}));
		this.templatesHintHide();
	}

	async ctaClickHandler(): Promise<void> {
		await this.trackingService.trackEvent(new TemplatesHintCtaOpenedEventBuilder({}));
	}

	async copyChatFileToFolder(): Promise<void> {
		const [message, projectId, currentSelectedFolder] = await combineLatest([
			this.chatMessageToShare$,
			this.selectedProjectId$,
			this.currentSelectedFolder$,
		])
			.pipe(take(1))
			.toPromise();

		await this.fileService.copyMessageFileToFiles(
			message,
			projectId,
			currentSelectedFolder ? currentSelectedFolder : null,
		);

		this.alertService.showAlert('files-section.messages.chat-to-files.success');
		this.clearFilesSelection();
	}

	async onClickTemplatesPromotionalContentHideButton(): Promise<void> {
		this.localStorageService.set('companyTemplatesPromotionalContent', true);
		await this.trackingService.trackEvent(new TemplatesOnboardingClosedEventBuilder({}));

		this.templatesPromotionalContentHide();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	dragOverHandler($event: DragEvent): void {
		$event.preventDefault();
	}

	private countSubFolders(folderId: string): number {
		const directChildren = this.allProjectFilesFolders.filter((file) => file.parentId === folderId);
		let subFolderCount = 0;

		for (const child of directChildren) {
			if (child.fileType === FileType.FOLDER) {
				subFolderCount += 1; // Count the subfolder itself
				subFolderCount += this.countSubFolders(child.id); // Recursively count subfolders
			}
		}

		return subFolderCount;
	}

	private listenUrlParam(paramName: string): Observable<string> {
		return this.activatedRoute.params.pipe(map((params) => params[paramName]));
	}

	private async getBreadcrumbPath(file: ProjectFile): Promise<ProjectFile[]> {
		const allFiles = await firstValueFrom(this.selectFilesInFilesSection$);
		const breadcrumbPath: ProjectFile[] = [];

		while (file && file.parentId) {
			const parentFile = allFiles.find((f) => f.id === file.parentId);
			if (!parentFile) {
				break;
			}

			breadcrumbPath.unshift(parentFile);
			file = parentFile;
		}

		return breadcrumbPath;
	}

	private listenOpenUrlParam(): void {
		this.listenUrlParam('openFileFolderId')
			.pipe(
				switchMap((fileFolderId) =>
					this.selectFilesInFilesSection$.pipe(
						map((files) => files.find((file) => file.id === fileFolderId)),
					),
				),
				filter(Boolean),
				takeUntil(this.destroy$),
			)
			.subscribe(async (fileFolder: ProjectFile) => {
				let newUrl = this.router.url.replace(/\/files\/.*/, '/files');

				// If the file is in a different project, navigate to root of file section
				if (
					(fileFolder.base as FileEntity).projectId &&
					(fileFolder.base as FileEntity).projectId !==
					(await firstValueFrom(this.selectedProjectId$))
				) {
					await this.router.navigate([newUrl]);
					return;
				}

				// Files can not be opened. If it's a file in a folder open its parent otherwise go to root
				if (fileFolder.fileType !== FileType.FOLDER) {
					if (fileFolder.parentId) {
						newUrl += `/${fileFolder.parentId}`;
					}
					await this.router.navigate([newUrl]);
					return;
				}

				const breadcrumbPath = await this.getBreadcrumbPath(fileFolder);
				this.goToRoot();
				this._folderBreadcrumbs.push(...breadcrumbPath);
				this.openFolder(fileFolder);
			});
	}

	private async openBasicUserSubscriptionDialog() {
		const doRedirection: boolean = await this.dialog
			.open(BasicUserSubscriptionDialogComponent, {
				data: {
					heading: 'files-section.basic-user-subscription-dialog.heading',
					points: [
						'files-section.basic-user-subscription-dialog.point-1',
						'files-section.basic-user-subscription-dialog.point-2',
						'files-section.basic-user-subscription-dialog.point-3',
					],
					upgradeButtonText:
						'dashboard.timeTracking.basic-user-subscription-dialog.upgrade-btn-text',
				},
				maxWidth: '60vw',
				autoFocus: false,
			})
			.afterClosed()
			.toPromise();

		if (doRedirection) {
			await this.routeToPaymentPage();
		}
	}

	private async routeToPaymentPage(): Promise<void> {
		await this.router.navigate(['/settings/subscription']);
	}

	private initAllProjectFilesFolders(): void {
		this.selectFilesInFilesSection$
			.pipe(
				takeUntil(this.destroy$),
				map((projectFiles) =>
					projectFiles.filter((projectFile) => projectFile.fileCategory !== 'company-file'),
				),
			)
			.subscribe((files) => (this.allProjectFilesFolders = files));
	}

	private async exportMultipleFiles(projectId: string, file: ProjectFile): Promise<void> {
		const filesToDownload = values(this.selectedFiles).length ? values(this.selectedFiles) : [file];
		const allSelectedFileIds = this.getAllSelectedFileIds(filesToDownload);

		const exportEntity: CreationExport = {
			format: 'application/zip',
			resourceId: projectId,
			resourceType: 'project',
			filter: {
				files: allSelectedFileIds,
			},
		};

		await this.exportService.createExport(exportEntity);
	}

	private async exportSingleFile(file: ProjectFile, projectId: string): Promise<void> {
		let fileSrc: string;
		if (file.fileUrl) {
			fileSrc = file.fileUrl;
		} else {
			fileSrc = await this.fileService.getFile(projectId, file, false);
		}

		this.baseFileService.downloadFile(fileSrc, file.name);
	}

	private getAllSelectedFileIds(selectedFiles: ProjectFile[], allSelectedFileIds = []): string[] {
		selectedFiles.forEach((fileItem) => {
			allSelectedFileIds.push(fileItem.id);

			if (fileItem.fileType === this.FILE_TYPE.FOLDER) {
				const filesInFolder = this.allProjectFilesFolders.filter(
					(file) => file.parentId === fileItem.id,
				);

				this.getAllSelectedFileIds(filesInFolder, allSelectedFileIds);
			}
		});

		return allSelectedFileIds;
	}

	private initProjectFilesSubscription(): void {
		this.selectedProjectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => {
			this.store.dispatch(initProjectFilesStateChangesAction({ projectId }));
		});
	}

	private initProjectChangeSubscription(): void {
		this.selectedProjectId$
			.pipe(takeUntil(this.destroy$))
			.subscribe(this.clearCurrentState.bind(this));
	}

	private initChatMessageToShareSubscription(): void {
		this.chatMessageToShare$.pipe(takeUntil(this.destroy$)).subscribe((messageToShare) => {
			if (!messageToShare) {
				return;
			}

			this.currentFileSelectionOperation$.next('copy-chat-file');
		});
	}

	private clearCurrentState(): void {
		this._folderBreadcrumbs = [];
		this.selectedFiles = {};
		this.filesToCopyOrMove = [];
		this.sourceFolder = null;
		this.currentSelectedFolder$.next(null);
		this.currentFileSelectionOperation$.next(null);
	}

	private sortBy(
		sortByOptions: ProjectFilesSortOptions,
		projectFiles: ProjectFile[] = [],
	): Promise<ProjectFile[]> {
		return new Promise((resolve) => {
			const sortedMembers = orderBy(
				projectFiles,
				[
					(file) => file.fileCategory !== 'company-file',
					sortByOptions.sortType === 'name'
						? (file) => file.name.toLowerCase()
						: sortByOptions.sortType,
				],
				['asc', sortByOptions.sortOrder],
			);

			resolve(sortedMembers);
		});
	}

	private async copyFilesToChatInOtherProject(filesToCopy: ProjectFile[]): Promise<void> {
		const confirmation = await this.dialog
			.open(CopyToOtherProjectDialogComponent, {
				width: '500px',
				data: {
					selectedFiles: filesToCopy,
					operation: 'chat',
				},
				autoFocus: false,
				disableClose: true,
			})
			.afterClosed()
			.pipe(take(1))
			.toPromise();

		if (!confirmation) {
			return;
		}

		this.alertService.showAlert('files-section.messages.success');
	}

	private async addFolder(folder: { name: string }): Promise<void> {
		const [userId, projectId, currentFolderId] = await combineLatest([
			this.store.select(selectUserId),
			this.selectedProjectId$,
			this.currentSelectedFolder$,
		])
			.pipe(take(1))
			.toPromise();

		const folderItem = new FileDocument(
			uuid(),
			folder.name,
			userId,
			projectId,
			currentFolderId ? currentFolderId : null,
			null,
			FILE_TYPE.FOLDER,
			moment().unix(),
		);

		this.fileService.createFolder(folderItem);
	}

	private async getSelectedFiles(file: ProjectFile): Promise<ProjectFile[]> {
		if (this.selectedFilesLength <= 1) {
			return [file];
		}

		return Object.keys(this.selectedFiles).map((fileId) =>
			find(this.allProjectFilesFolders, { id: fileId }),
		);
	}

	private clearFilesSelection(): void {
		this.filesToCopyOrMove = [];
		this.selectedFiles = {};
		this.sourceFolder = null;
		this.currentFileSelectionOperation$.next(null);
		this.store.dispatch(clearSelectedChatMessageToShareAction());
	}

	private userUploadedTemplates(): Observable<CompanyFile[]> {
		return this.store
			.select(selectAllCompanyFiles)
			.pipe(
				map((companyFilesList) =>
					companyFilesList.filter((file) => this.isUserUploadedTemplate(file.name)),
				),
			);
	}

	// TODO:: This will be removed once the default templates are moved to their own folder.
	private isUserUploadedTemplate(name: string): boolean {
		return !/\d{2}_.*\.pdf$/.test(name);
	}

	private templatesHintHide(): void {
		this.templatesHintDismissed$.next(true);
	}

	private templatesHintShow(): void {
		this.templatesHintDismissed$.next(false);
	}

	private async confirmMove(): Promise<void> {
		const destinationFolderId = await this.currentSelectedFolder$.pipe(take(1)).toPromise();
		this.moveFiles(destinationFolderId);
	}

	private async moveFiles(destinationFolderId: string): Promise<void> {
		await Promise.all(
			this.filesToCopyOrMove.map((file) =>
				this.fileService.updateFileOrFolderById(file.id, {
					folderId: destinationFolderId,
				}),
			),
		);

		const projectId = await this.selectedProjectId$.pipe(take(1)).toPromise();
		await Promise.all(
			this.filesToCopyOrMove.map((file) =>
				this.trackingService.trackEvent(
					new FilesFileMovedEventBuilder({
						projectId,
						type: file.base.type === FileType.FOLDER ? 'folder' : 'file',
					}),
				),
			),
		);

		this.alertService.showAlert('files-section.messages.move-with-in-project.success');
		this.clearFilesSelection();
	}

	private initTemplatesHintVisibleStream(): Observable<boolean> {
		return combineLatest([
			this.isCompanyFolder$.pipe(distinctUntilChanged()),
			this.templatesHintDismissed$,
		]).pipe(
			switchMap(([isCompanyFolder, templatesHintDismissed]) => {
				if (!isCompanyFolder && templatesHintDismissed) {
					this.templatesHintShow();
				}

				if (templatesHintDismissed || !isCompanyFolder) {
					return of(false);
				}

				return this.userUploadedTemplates().pipe(
					take(1),
					map((templates) => templates.length === 0),
				);
			}),
		);
	}

	private templatesPromotionalContentHide(): void {
		this.templatesPromotionalContentDismissed$.next(true);
	}

	private async confirmCopyWithInProject(): Promise<void> {
		const projectId = await this.selectedProjectId$.pipe(take(1)).toPromise();
		const destinationFolderId = await this.currentSelectedFolder$.pipe(take(1)).toPromise();
		await this.fileService.copyFilesToProject(
			this.filesToCopyOrMove.map((file) => file.base as FileEntity),
			projectId,
			destinationFolderId,
		);

		await Promise.all(
			this.filesToCopyOrMove.map(() =>
				this.trackingService.trackEvent(
					new FilesFileCopiedEventBuilder({
						projectId,
					}),
				),
			),
		);

		this.alertService.showAlert('files-section.messages.copy-with-in-project.success');
		this.clearFilesSelection();
	}

	private initTemplatesPromotionalContentVisibleStream(): Observable<boolean> {
		return combineLatest([
			this.isCompanyFolder$.pipe(distinctUntilChanged()),
			this.templatesPromotionalContentDismissed$,
		]).pipe(
			switchMap(([isCompanyFolder, templatesPromotionalContentDismissed]) => {
				if (!isCompanyFolder && templatesPromotionalContentDismissed) {
					return of(false);
				}

				if (this.localStorageService.getSync('companyTemplatesPromotionalContent') !== true) {
					return timer(2000).pipe(
						take(1),
						map(() => true),
					);
				}

				return of(false);
			}),
		);
	}
}
