import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'ch-promotional-content',
	templateUrl: './promotional-content.component.html',
	styleUrls: ['./promotional-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromotionalContentComponent {
	@Input() orientation: 'row' | 'column' = 'row';

	constructor() { }
}
