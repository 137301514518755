import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { Routes } from '@angular/router';
import { redirectUnauthenticatedToLogin } from '@injectables/services/route-guard-functions';
import { UploadGuard } from '@injectables/guards/upload/upload.guard';
import { WorkComponent } from './work.component';
import { ProjectInviteComponent } from './project-invite/project-invite.component';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import { ProjectRouteGuard } from '@injectables/guards/project-route/project-route.guard';
import { ProjectLastOpenedGuard } from '@injectables/guards/project-last-opened/project-last-opened.guard';
import { FilesSectionComponent } from './files-section/components/files-section/files-section.component';
import { EmptyComponent } from '@modules/shared/components/empty-component/empty.component';
import { ProjectTasksListComponent } from './project-tasks-list/project-tasks-list.component';
import { ProjectTaskEditComponent } from './tasks/project-task-edit/project-task-edit.component';
import { ProjectTaskCreateComponent } from './project-task-create/project-task-create.component';
import { TrackedTimesProjectsComponent } from '@modules/shared/components/time-tracking-add-edit/components/tracked-times-projects/tracked-times-projects.component';
import { HasUnsavedChangesGuard } from '@injectables/guards/has-unsaved-changes/has-unsaved-changes.guard';
import { RightSideMenuGuard } from '@injectables/guards/no-folders/right-side-menu.guard';
import { ProjectMemberDrawerComponent } from '@work/project-members/components/project-member-drawer/project-member-drawer.component';

const menuRoutes: Routes = [
	{
		path: ':projectId',
		data: {
			projectMenu: true,
		},
		children: [
			{
				path: '',
				component: EmptyComponent,
			},
			{
				path: 'details',
				component: ProjectEditComponent,
				canDeactivate: [HasUnsavedChangesGuard],
			},
			{
				path: 'files/:openFileFolderId',
				canActivate: [RightSideMenuGuard],
				component: FilesSectionComponent,
			},
			{
				path: 'files',
				canActivate: [RightSideMenuGuard],
				loadChildren: () =>
					import('./files-section/files-section.module').then((m) => m.FilesSectionModule),
			},
			{
				path: 'tasks',
				canActivate: [RightSideMenuGuard],
				children: [
					{
						path: '',
						component: ProjectTasksListComponent,
					},
					{
						path: 'create',
						component: ProjectTaskCreateComponent,
					},
					{
						path: ':taskId/edit',
						component: ProjectTaskEditComponent,
					},
				],
			},
			{
				path: 'time-tracking',
				canActivate: [RightSideMenuGuard],
				children: [
					{
						path: '',
						component: TrackedTimesProjectsComponent,
					},
					{
						path: 'create',
						component: TrackedTimesProjectsComponent,
					},
					{
						path: ':trackedTimeId/edit',
						component: TrackedTimesProjectsComponent,
					},
				],
			},
			{
				path: 'members',
				component: ProjectMemberDrawerComponent,
			},
		],
	},
];
// FIXME: remove projects/:projectId/project-item-new and archive/:projectId/project-item-new on integration
export const workRoute: Routes = [
	{
		path: 'project',
		redirectTo: 'projects',
	},
	{
		path: 'projects',
		component: WorkComponent,
		data: {
			pageTitle: 'title.project',
			authGuardPipe: redirectUnauthenticatedToLogin,
		},
		children: menuRoutes,
		canActivate: [AngularFireAuthGuard, ProjectRouteGuard, ProjectLastOpenedGuard],
		canActivateChild: [ProjectLastOpenedGuard],
		canDeactivate: [UploadGuard],
	},
	{
		path: 'archive',
		component: WorkComponent,
		children: menuRoutes,
		data: {
			archive: true,
			pageTitle: 'title.project',
			authGuardPipe: redirectUnauthenticatedToLogin,
		},
		canActivate: [AngularFireAuthGuard, ProjectRouteGuard, ProjectLastOpenedGuard],
		canActivateChild: [ProjectLastOpenedGuard],
		canDeactivate: [UploadGuard],
	},
	{
		path: 'archive',
		component: WorkComponent,
		data: {
			pageTitle: 'title.project',
			authGuardPipe: redirectUnauthenticatedToLogin,
		},
		pathMatch: 'full',
		canActivate: [AngularFireAuthGuard],
	},
	{
		path: 'inviteLink',
		component: ProjectInviteComponent,
		canActivate: [AngularFireAuthGuard],
		data: {
			pageTitle: 'title.project',
			authGuardPipe: redirectUnauthenticatedToLogin,
		},
	},
];
