<div *ngIf="trialSubscription?.hasRunningTrial" class="w-100 d-flex align-items-center justify-content-between banner font-weight-light pl-5">
	<div class="d-flex align-items-center">
		<span class="font-weight-bold mr-1">{{ trialSubscription?.remainingTrialDays }}</span>
		<span>{{ 'trialBanner.daysRemaining' | translate }}</span>
	</div>
	<div class="pr-5 d-flex align-items-center">
		<span>{{ 'trialBanner.enjoyPro' | translate }}</span>
		<button (click)="navigateToProducts()" class="mx-3 font-weight-bold" mat-stroked-button>
			{{ 'trialBanner.upgradeNow' | translate }}
		</button>
	</div>
</div>
