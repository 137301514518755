import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ShrinkOutAnimation } from '@shared/animations/shrink-out-animation';
import { OrderByType, Project, SortByType, SortOptions } from 'domain-entities';
import { ProfileSettingService } from '@injectables/services/profile-setting.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, first, map, pairwise, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import {
	isOwnerOfCompany,
	selectCompanyMembersAcceptedArray,
	selectProjectStatus,
	selectUserId,
} from '@store/selectors/app.selectors';
import {
	setActiveProjectsSearchTerm,
	setArchivedProjectsSearchTerm,
} from '@store/actions/project.actions';
import {
	selectAllActiveProjects,
	selectAllArchivedProjects,
} from '@store/selectors/projects.selectors';
import { Menu } from '@craftnote/material-theme';
import { filter, ProjectFilter } from '@shared/functions/project/project-filter.functions';
import { createKeyMap } from '@shared/functions/utils/object.functions';
import { UntypedFormBuilder } from '@angular/forms';
import { MultiSelectPanelConfig } from '@modules/shared/components/multi-select-panel/multi-select-panel.component';
import { TranslateService } from '@ngx-translate/core';
import {
	FilterDefaultConfig,
	ProjectFilterFormHelper,
	SortDefaultConfig,
} from '@work/project-search/helpers/project-filter-form.helper';
import { TimeRange } from '@globalTypes/interfaces/time-range.types';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DateRangeDialogTexts } from '@work/project-search/components/date-range-dialog/date-range-dialog.component';
import { MemberFullNamePipe } from '@modules/shared/pipes/member-full-name/member-full-name.pipe';
import { RoleNamePipe } from '@modules/shared/pipes/role-name/role-name.pipe';
import { selectHasCurrentUserProfileLimitsFactory } from '@store/selectors/profile-limits.selectors';
import { ProjectFilters } from '@shared/models/project-filters.model';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	ProjectsProjectFilterpaywallOpenedEventBuilder,
	ProjectsProjectFilterUpdatedEventBuilder,
} from '@generated/events/ProjectsEvents.generated';
import { ProjectAddressPipe } from '@modules/shared/pipes/project-address/project-address.pipe';
import { isEqual } from 'lodash';
import { ProjectAreaStore } from '@modules/shared/components/projects-area/store/project-area.store';
import { GroupByOption, ProjectSortConfig } from '@work/project-search/types/project-sort.types';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { LocalStorageService } from '@injectables/services/local-storage.service';
import { ProjectSortAndSearchHelper } from '@injectables/services/project-sort-and-search-helper.service';

@Component({
	selector: 'app-project-search',
	templateUrl: './project-search.component.html',
	styleUrls: ['./project-search.component.scss'],
	animations: [ShrinkOutAnimation],
})
export class ProjectSearchComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	@Input() projects: Project[] = [];

	@Output() editProjectsChange = new EventEmitter<Project[]>();
	@Output() filterActive = new EventEmitter<boolean>();
	@Output() inputClear = new EventEmitter();
	@Output() groupBy = new EventEmitter<GroupByOption>();

	@Input() isArchived;

	@ViewChild('drop') filterDropdownRef: TemplateRef<any>;
	@ViewChild('folder') folderRef: TemplateRef<any>;
	@ViewChild('startDate') startDateRef: TemplateRef<any>;
	@ViewChild('endDate') endDateRef: TemplateRef<any>;
	@ViewChild('employee') employeeRef: TemplateRef<any>;
	@ViewChild('filterPaywall') filterPaywallRef: TemplateRef<any>;
	@ViewChild('groupingPaywall') groupingPaywall: TemplateRef<any>;
	@ViewChild('matMenuTrigger') matMenuTrigger: MatMenuTrigger;
	isHidden = false;

	filterControlGroup = this.formBuilder.group(FilterDefaultConfig);
	sortControl = this.formBuilder.control(SortDefaultConfig);

	formHelper = new ProjectFilterFormHelper(this.filterControlGroup);

	userId: string;
	destroy$ = new Subject();
	isOwner$ = this.store.select(isOwnerOfCompany);
	projectStatuses: { [id: string]: string } = {};

	private hasCurrentUserFilterProfileLimits$ = this.store.select(
		selectHasCurrentUserProfileLimitsFactory('projectListFilters'),
	);

	private hasCurrentUserGroupingProfileLimits$ = this.store.select(
		selectHasCurrentUserProfileLimitsFactory('projectListGrouping'),
	);

	private statusFilterMenu: Menu = {
		isRoot: false,
		matIcon: false,
		triggerName: this.translateService.instant('projectList_filterOptions_options_status'),
		customIconName: 'custom-status',
	};

	private folderFilterMenu: Menu = {
		isRoot: false,
		matIcon: false,
		openInModal: false,
		triggerName: this.translateService.instant('projectList_filterOptions_options_folder'),
		customIconName: 'custom-folder',
	};

	private startDateDialog: Menu = {
		isRoot: false,
		matIcon: false,
		openInModal: true,
		triggerName: this.translateService.instant('projectList_filterOptions_options_startDate'),
		customIconName: 'custom-start-date',
	};

	private endDateDialog: Menu = {
		isRoot: false,
		matIcon: false,
		openInModal: true,
		triggerName: this.translateService.instant('projectList_filterOptions_options_endDate'),
		customIconName: 'custom-end-date',
	};

	private employeeDialog: Menu = {
		isRoot: false,
		matIcon: false,
		openInModal: false,
		triggerName: this.translateService.instant('projectList_filterOptions_options_employees'),
		customIconName: 'custom-employees',
	};

	config: Menu = {
		disableMenu: true,
		isRoot: true,
		triggerName: 'filter_alt',
		matIcon: true,
		options: [
			this.employeeDialog,
			this.folderFilterMenu,
			this.statusFilterMenu,
			this.startDateDialog,
			this.endDateDialog,
		],
	};

	statusIdsConfig$ = this.store.select(selectProjectStatus).pipe(
		map(
			(state) =>
			({
				options: [
					{
						value: null,
						displayValue: this.translateService.instant(
							'projectList_filterOptions_options_status_noStatus',
						),
					},
					...state.map((status) => ({ value: status.id, displayValue: status.name })),
				],
			} as MultiSelectPanelConfig),
		),
	);

	employeesConfig$ = this.store.select(selectCompanyMembersAcceptedArray).pipe(
		map(
			(members) =>
			({
				options: members
					.map((member) => ({
						value: member.id,
						displayValue: this.memberFullNamePipe.transform(member, 'email', true),
						hintValue: this.roleNamePipe.transform(member.role),
					}))
					.sort((projectA, projectB) =>
						projectA.displayValue.localeCompare(projectB.displayValue),
					),
			} as MultiSelectPanelConfig),
		),
	);

	foldersConfig: MultiSelectPanelConfig;
	startDateTextConfig: DateRangeDialogTexts = {
		displayHint: 'projectList_dateFilter_start_displayHint',
		displaySubHint: 'projectList_dateFilter_start_displaySubHint',
		rangeHint: 'projectList_dateFilter_start_rangeHint',
	};

	endDateTextConfig: DateRangeDialogTexts = {
		displayHint: 'projectList_dateFilter_end_displayHint',
		displaySubHint: 'projectList_dateFilter_end_displaySubHint',
		rangeHint: 'projectList_dateFilter_end_rangeHint',
	};

	get isSortMenuOpen(): boolean {
		return this.matMenuTrigger?.menuOpen ?? false;
	}

	constructor(
		private projectSortAndSearchHelper: ProjectSortAndSearchHelper,
		private readonly profileSettingService: ProfileSettingService,
		private readonly translateService: TranslateService,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly memberFullNamePipe: MemberFullNamePipe,
		private readonly roleNamePipe: RoleNamePipe,
		private readonly store: Store<AppState>,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly localStorageService: LocalStorageService,
		private readonly trackingService: TrackingService,
		private readonly projectAddress: ProjectAddressPipe,
		private readonly projectAreaStore: ProjectAreaStore,
		private readonly matDialog: MatDialog,
	) { }

	ngAfterViewInit(): void {
		this.config.options[0].ref = this.employeeRef;
		this.config.disableMenuRef = this.filterPaywallRef;
		this.config.disableMenuText = this.translateService.instant(
			'projectList_filters_paywallButton',
		);
		this.folderFilterMenu.ref = this.folderRef;
		this.startDateDialog.ref = this.startDateRef;
		this.endDateDialog.ref = this.endDateRef;
		this.statusFilterMenu.ref = this.filterDropdownRef;
		this.initWatchProjectFilterRights();
		this.changeDetector.detectChanges();
	}

	async ngOnInit(): Promise<void> {
		this.initUserId();
		this.initProjectStatuses();
		this.initOnFilterChange();
		await this.initSortBy();
		this.initSetGroupingOptionFromProfileLimit();
		this.initFiltersFromLocalStorage();
		this.initOpenFolderChange();
		this.fireProjectFiltersEvents();
	}

	async ngOnChanges(): Promise<void> {
		void this.updateVisibleProjects();
		await this.getFoldersConfig();
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	get filterNumberText(): string {
		return this.formHelper.getNumberOfActiveNonSearchFilters > 1
			? 'projectList_filterOptions_hints_numberOfActivePlural'
			: 'projectList_filterOptions_hints_numberOfActiveSingular';
	}

	private initFiltersFromLocalStorage(): void {
		const parsedProjectFilters = this.localStorageService.getSync(
			this.isArchived ? 'archivedProjectFilters' : 'activeProjectFilters',
		);
		if (parsedProjectFilters && parsedProjectFilters[this.userId]) {
			this.formHelper.setFormGroupValues(parsedProjectFilters[this.userId]);
		}
	}

	private async getFoldersConfig(): Promise<void> {
		const sortProjects = await this.sort(this.projects);
		const folders = sortProjects
			.map((project) => {
				if (project.projectType === 'FOLDER') {
					return {
						value: project.id,
						displayValue: project.name,
						hintValue: this.projectAddress.transform(project),
						secondHintValue: project.orderNumber,
					};
				}
			})
			.filter((project) => !!project);

		this.foldersConfig = { options: folders };
	}

	private initProjectStatuses(): void {
		this.store
			.select(selectProjectStatus)
			.pipe(takeUntil(this.destroy$))
			.subscribe((projectStatuses) => {
				this.projectStatuses = projectStatuses.reduce((acc, status) => {
					acc[status.id] = status.name;
					return acc;
				}, {} as { [ids: string]: string });
			});
	}

	private initUserId(): void {
		this.store
			.select(selectUserId)
			.pipe(takeUntil(this.destroy$))
			.subscribe((userId) => (this.userId = userId));
	}

	private initOpenFolderChange(): void {
		this.projectAreaStore.openFolder$
			.pipe(takeUntil(this.destroy$), pairwise())
			.subscribe(([previousOpenFolder, newOpenFolder]) => {
				if (previousOpenFolder?.id !== newOpenFolder?.id) {
					this.formHelper.searchControl.setValue('');
				}
			});
	}

	async sort(projects: Project[]): Promise<Project[]> {
		const allProjects$ = this.isArchived
			? this.store.select(selectAllArchivedProjects)
			: this.store.select(selectAllActiveProjects);
		return this.projectSortAndSearchHelper.sort(
			projects,
			this.sortControl.value.sortBy,
			this.sortControl.value.sortDirection,
			await allProjects$.pipe(first()).toPromise(),
		);
	}

	searchProjects(projects: Project[]): Project[] {
		let searchedProjects = projects.filter((project) =>
			this.checkProject(this.formHelper.searchControlValue, project, this.userId),
		);

		const groupByValue = this.sortControl.value.groupBy || GroupByOption.NONE;

		if (!this.formHelper.anyFilterActive && groupByValue === GroupByOption.NONE) {
			const projectMap = createKeyMap<Project>((project) => project.id, searchedProjects);
			searchedProjects = searchedProjects.filter(
				(p) => !p.parentProject || !projectMap[p.parentProject],
			);
		}

		if (this.isArchived) {
			this.store.dispatch(
				setArchivedProjectsSearchTerm({
					archivedProjectsSearchTerm: this.formHelper.searchControlValue,
				}),
			);
		} else {
			this.store.dispatch(
				setActiveProjectsSearchTerm({
					activeProjectsSearchTerm: this.formHelper.searchControlValue,
				}),
			);
		}
		return searchedProjects;
	}

	checkProject(search, project: Project, userId: string): boolean {
		return this.projectSortAndSearchHelper.defaultSearchCondition(project, search, {
			userId,
			projectStatuses: this.projectStatuses,
		});
	}

	async initSortBy(): Promise<void> {
		this.sortControl.valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(async (value: ProjectSortConfig) => {
				const groupByValue = value.groupBy ?? GroupByOption.NONE;
				const sortOptions: SortOptions = {
					sortBy: value.sortBy,
					orderBy: value.sortDirection,
				};
				this.groupBy.emit(groupByValue);
				await this.profileSettingService.updateSortByOptions(sortOptions);
				await this.persistGroupByOption(groupByValue);
				await this.updateVisibleProjects();
				await this.getFoldersConfig();
			});

		const groupBy = this.localStorageService.getSync('projectListGroupBy') || GroupByOption.NONE;

		const persistedSortOptions = (
			await this.profileSettingService.getProfileSettings().pipe(take(1)).toPromise()
		)?.sortOptions;

		const sortBy = persistedSortOptions?.sortBy || SortByType.ALPHABETICAL;
		const sortDirection = persistedSortOptions?.orderBy || OrderByType.ASC;

		this.sortControl.setValue({
			sortBy,
			groupBy,
			sortDirection,
		});
	}

	initSetGroupingOptionFromProfileLimit(): void {
		this.hasCurrentUserGroupingProfileLimits$
			.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
			.subscribe((hasLimit) => {
				if (hasLimit) {
					const groupBy =
						this.localStorageService.getSync('projectListGroupBy') || GroupByOption.NONE;
					this.sortControl.setValue({ ...this.sortControl.value, groupBy });
				} else {
					this.sortControl.setValue({ ...this.sortControl.value, groupBy: GroupByOption.NONE });
				}
			});
	}

	private async persistGroupByOption(option: GroupByOption): Promise<void> {
		const hasLimits = await this.hasCurrentUserGroupingProfileLimits$.pipe(take(1)).toPromise();
		if (!hasLimits) {
			return;
		}
		await this.localStorageService.set('projectListGroupBy', option);
	}

	initOnFilterChange(): void {
		this.filterControlGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((formValues) => {
			this.config.showActive = this.formHelper.anyNonSearchFilterActive;
			this.statusFilterMenu.showActive = !!this.formHelper.isStatusActive;
			this.folderFilterMenu.showActive = !!this.formHelper.isFolderActive;
			this.startDateDialog.showActive = !!this.formHelper.isStartDateActive;
			this.endDateDialog.showActive = !!this.formHelper.isEndDateActive;
			this.employeeDialog.showActive = !!this.formHelper.isEmployeesActive;
			this.filterActive.next(this.formHelper.anyFilterActive);
			void this.updateVisibleProjects();

			const { status, folder, startDate, endDate, employees } = formValues;

			this.updateProjectFiltersStorage({
				status,
				folder,
				startDate,
				endDate,
				employees,
			});
		});
	}

	initWatchProjectFilterRights(): void {
		this.hasCurrentUserFilterProfileLimits$
			.pipe(takeUntil(this.destroy$))
			.subscribe((hasRights) => {
				this.config.disableMenu = !hasRights;
			});
	}

	resetAllNonSearchFilters(): void {
		this.formHelper.resetAllNonSearchFilters();
		this.removeUserProjectFilters();
	}

	private async updateVisibleProjects(): Promise<void> {
		let projects = this.projects;

		const statusFilter = this.formHelper.statusControlValue;
		const folderFilter = this.formHelper.folderControlValue;
		const startDateFilter = this.formHelper.startDateControlValue;
		const endDateFilter = this.formHelper.endDateControlValue;
		const employeeFilter = this.formHelper.employeesControlValue;

		projects = filter(projects, {
			filterType: ProjectFilter.EMPLOYEE,
			projectListType: this.isArchived ? 'archive' : 'active',
			employeeIds: employeeFilter,
		});

		if (folderFilter.length) {
			projects = filter(projects, {
				filterType: ProjectFilter.FOLDER,
				folderIds: folderFilter || [],
			});
		}

		if (statusFilter.length) {
			projects = filter(projects, {
				filterType: ProjectFilter.STATUS,
				statusIds: statusFilter || [],
			});
		}

		projects = filter(projects, {
			filterType: ProjectFilter.START_DATE,
			timeRange: startDateFilter,
		});

		projects = filter(projects, {
			filterType: ProjectFilter.END_DATE,
			timeRange: endDateFilter,
		});

		projects = this.searchProjects(projects);
		projects = await this.sort(projects);
		this.editProjectsChange.emit(projects);
	}

	onStartDateFilterSet(range: TimeRange, ref: MatDialogRef<any>): void {
		ref.close();
		this.formHelper.startDateControl.setValue(range);
	}

	onEndDateFilterSet(range: TimeRange, ref: MatDialogRef<any>): void {
		ref.close();
		this.formHelper.endDateControl.setValue(range);
	}

	async fireProjectFilterpaywallEvent(): Promise<void> {
		await this.trackingService.trackEvent(new ProjectsProjectFilterpaywallOpenedEventBuilder({}));
	}

	async openGroupingPaywall(): Promise<void> {
		this.matDialog.open(this.groupingPaywall, { autoFocus: false, maxWidth: '1000px' });
	}

	private updateProjectFiltersStorage(projectFilters: ProjectFilters): void {
		if (this.formHelper.anyNonSearchFilterActive) {
			this.setUserProjectFilters(projectFilters);
		} else {
			this.removeUserProjectFilters();
		}
	}

	private removeUserProjectFilters(): void {
		const localStorageProjectFilters = this.localStorageService.getSync(
			this.isArchived ? 'archivedProjectFilters' : 'activeProjectFilters',
		);

		if (!localStorageProjectFilters) {
			return;
		}
		delete localStorageProjectFilters[this.userId];

		this.localStorageService.set(
			this.isArchived ? 'archivedProjectFilters' : 'activeProjectFilters',
			localStorageProjectFilters,
		);
	}

	private setUserProjectFilters(projectFilters: ProjectFilters): void {
		const userFilters = {
			[this.userId]: projectFilters,
		};
		const localStorageProjectFilters = this.localStorageService.getSync(
			this.isArchived ? 'archivedProjectFilters' : 'activeProjectFilters',
		);
		const projectFiltersUpdated: ProjectFilters = {
			...(localStorageProjectFilters || {}),
			...userFilters,
		};

		this.localStorageService.set(
			this.isArchived ? 'archivedProjectFilters' : 'activeProjectFilters',
			projectFiltersUpdated,
		);
	}

	private fireProjectFiltersEvents(): void {
		this.filterControlGroup
			.get('status')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(async (status) => {
				if (!isEqual(status, FilterDefaultConfig.status[0])) {
					await this.trackingService.trackEvent(
						new ProjectsProjectFilterUpdatedEventBuilder({ filterType: 'status' }),
					);
				}
			});

		this.filterControlGroup
			.get('startDate')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(async (startDate) => {
				if (!isEqual(startDate, FilterDefaultConfig.startDate[0])) {
					await this.trackingService.trackEvent(
						new ProjectsProjectFilterUpdatedEventBuilder({ filterType: 'startDate' }),
					);
				}
			});

		this.filterControlGroup
			.get('endDate')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(async (endDate) => {
				if (!isEqual(endDate, FilterDefaultConfig.endDate[0])) {
					await this.trackingService.trackEvent(
						new ProjectsProjectFilterUpdatedEventBuilder({ filterType: 'endDate' }),
					);
				}
			});

		this.filterControlGroup
			.get('employees')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(async (employees) => {
				if (!isEqual(employees, FilterDefaultConfig.employees[0])) {
					await this.trackingService.trackEvent(
						new ProjectsProjectFilterUpdatedEventBuilder({ filterType: 'employees' }),
					);
				}
			});

		this.filterControlGroup
			.get('folder')
			.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(async (folder) => {
				if (!isEqual(folder, FilterDefaultConfig.folder[0])) {
					await this.trackingService.trackEvent(
						new ProjectsProjectFilterUpdatedEventBuilder({ filterType: 'folders' }),
					);
				}
			});
	}
}
