import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ScheduleDemoService } from '@injectables/services/schedule-demo/schedule-demo.service';
import { UpgradesTrialbannerCtaSelectedEventBuilder } from '@generated/events/UpgradesEvents.generated';
import { TrackingService } from '@injectables/services/tracking.service';

@Component({
	selector: 'app-trial-banner',
	templateUrl: './trial-banner.component.html',
	styleUrls: ['./trial-banner.component.scss'],
})
export class TrialBannerComponent {
	@Input() trialSubscription: {
		remainingTrialDays: number;
		hasRunningTrial: boolean;
	};

	constructor(
		private readonly router: Router,
		private readonly trackingService: TrackingService,
		public readonly scheduleDemoService: ScheduleDemoService,
	) { }

	navigateToProducts(): void {
		this.trackTrialBannerUpgradeEvent();
		this.router.navigateByUrl('settings/subscription/products');
	}

	async trackTrialBannerUpgradeEvent(): Promise<void> {
		await this.trackingService.trackEvent(new UpgradesTrialbannerCtaSelectedEventBuilder({}));
	}
}
