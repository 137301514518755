import { Company, InviteState, Member, MemberRole } from 'domain-entities';
import { CompanySize } from '@shared/models/company-size.type';
import { isNil } from 'lodash';

export function getNumberOfOwnersInCompany(company: Company): number {
	return Object.values(company.members).filter(
		(member) => member.role === MemberRole.OWNER && member.inviteState === InviteState.ACCEPTED,
	).length;
}

export function isUserOwnerOfCompany(email: string, company: Company): boolean {
	return company.members[email]?.role === MemberRole.OWNER;
}

export function companySizeInRange(
	currentCompanySize: CompanySize,
	comparingWithCompanySize: CompanySize,
): boolean {
	// There is a case when there is no upper value so TO is undefined
	if (isNil(currentCompanySize.to) || isNil(comparingWithCompanySize.to)) {
		return comparingWithCompanySize.from <= currentCompanySize.from;
	}
	return (
		comparingWithCompanySize.from <= currentCompanySize.from &&
		currentCompanySize.to <= comparingWithCompanySize.to
	);
}

export function isCompanyAdmin(member: Member): boolean {
	return [MemberRole.OWNER, MemberRole.SUPERVISOR].includes(member?.role);
}
