import { NgModule } from '@angular/core';
import { ButtonLoaderModule } from '@craftnote/material-theme';
import { TrackedTimesListComponent } from './components/tracked-times-projects/tracked-times-list/tracked-times-list.component';
import { TrackedTimeAddEditPanelComponent } from './components/tracked-time-add-edit-panel/tracked-time-add-edit-panel.component';
import { TrackedTimeAddEditFormComponent } from './components/tracked-time-add-edit-panel/tracked-time-add-edit-form/tracked-time-add-edit-form.component';
import { TaskTimeEditPanelComponent } from './components/task-time-edit-panel/task-time-edit-panel.component';
import { TaskTimeEditFormComponent } from './components/task-time-edit-panel/task-time-edit-form/task-time-edit-form.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TrackedTimePauseButtonComponent } from './components/tracked-time-add-edit-panel/tracked-time-add-edit-form/tracked-time-pause-button/tracked-time-pause-button.component';
import { TrackedTimeWorkTypeButtonComponent } from './components/tracked-time-add-edit-panel/tracked-time-add-edit-form/tracked-time-work-type-button/tracked-time-work-type-button.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { WorkTypeListPanelComponent } from './components/tracked-time-add-edit-panel/tracked-time-add-edit-form/work-type-list-panel/work-type-list-panel.component';
import { TimeTrackingAssigneeInputComponent } from './components/time-tracking-assignee-input/time-tracking-assignee-input.component';
import { TrackedTimesProjectsComponent } from './components/tracked-times-projects/tracked-times-projects.component';
import { MatIconSizeModule } from '../../directives/mat-icon-size.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@craftnote/shared-angular-modules';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TimePickerModule } from '../time-picker/time-picker.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MemberFullNamePipeModule } from '../../pipes/member-full-name/member-full-name-pipe.module';
import { ProjectsSelectionFilterModule } from '../projects-selection-filter/projects-selection-filter.module';
import { UnixToMillisPipeModule } from '../../pipes/unix-to-millis/unix-to-millis-pipe.module';
import { TrackedTimeAllChangesComponent } from './components/tracked-time-add-edit-panel/tracked-time-all-changes/tracked-time-all-changes.component';
import { TrackedTimeLastChangesComponent } from './components/tracked-time-add-edit-panel/tracked-time-add-edit-form/tracked-time-last-changes/tracked-time-last-changes.component';
import { MatIconDisabledModule } from '@craftnote/material-theme';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const components = [
	TrackedTimePauseButtonComponent,
	WorkTypeListPanelComponent,
	TrackedTimesListComponent,
	TrackedTimeAddEditPanelComponent,
	TrackedTimeAddEditFormComponent,
	TaskTimeEditPanelComponent,
	TaskTimeEditFormComponent,
	TrackedTimeWorkTypeButtonComponent,
	TimeTrackingAssigneeInputComponent,
	TrackedTimesProjectsComponent,
	TrackedTimeAllChangesComponent,
	TrackedTimeLastChangesComponent,
];

@NgModule({
	declarations: [components, TrackedTimeAllChangesComponent, TrackedTimeLastChangesComponent],
	imports: [
		ButtonLoaderModule,
		MatDatepickerModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatTooltipModule,
		MatIconDisabledModule,
		ProjectsSelectionFilterModule,
		MatIconSizeModule,
		SharedModule,
		MatButtonModule,
		MatIconModule,
		SharedModule,
		MatFormFieldModule,
		MatMenuModule,
		TimePickerModule,
		ReactiveFormsModule,
		RouterModule,
		MatCheckboxModule,
		MatInputModule,
		MatRadioModule,
		MemberFullNamePipeModule,
		UnixToMillisPipeModule,
		MatProgressSpinnerModule,
	],
	exports: [components],
})
export class TimeTrackingAddEditModule { }
