import { Injectable } from "@angular/core";
import { PurchaseService } from "./purchase.service";
import { firstValueFrom } from "rxjs";
import { setPurchaseStateAction, optimimisticForceTrialEndedAction } from "./purchase-state";
import { Store } from "@ngrx/store";

@Injectable({
	providedIn: 'root'
})
export class PurchaseStateService {

	constructor(
		private readonly purchaseService: PurchaseService,
		private readonly store: Store
	) { }

	async refreshPurchaseState(): Promise<void> {
		const purchaseState = await firstValueFrom(this.purchaseService.getPurchaseDetails());
		this.store.dispatch(setPurchaseStateAction({ purchaseState }));
	}

	async optimimisticForceTrialEnded(): Promise<void> {
		this.store.dispatch(optimimisticForceTrialEndedAction());
	}
}
