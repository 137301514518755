import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CalendlyLinks, CalendlyService } from '@injectables/services/calendly.service';
import { LinkService } from '@injectables/services/link/link.service';
import { isOwnerOfCompany, selectUserRole } from '@store/selectors/app.selectors';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PaywallDialogService } from '@modules/shared/components/paywall-dialog/paywall-dialog.service';
import { PaywallDialogConfig } from '@modules/shared/components/paywall-dialog/paywall-dialog.types';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	ProjectmembersLimitPaywallOpenedEventBuilder,
	ProjectmembersLimitPaywallSelectedEventBuilder,
} from '@generated/events/ProjectmembersEvents.generated';
import { MemberRole } from "domain-entities";

export type NoProjectInvitationsRemainingPaywallContext =
	| 'project-creation'
	| 'project-members'
	| 'planning-board'
	| 'project-restore'
	| 'project-duplicate';

@Injectable({
	providedIn: 'root',
})
export class NoInvitationsRemainingService {
	async openNoProjectInvitationsRemainingPaywallDialog(
		context: NoProjectInvitationsRemainingPaywallContext,
		config?: PaywallDialogConfig,
	): Promise<'open-upgrade' | 'open-calendly'> {
		const role = (await firstValueFrom(this.store.select(selectUserRole))).toLowerCase();
		this.trackingService.trackEvent(
			new ProjectmembersLimitPaywallOpenedEventBuilder({ context, role }),
		);
		const secondaryButtonText: string = role === MemberRole.OWNER.toLowerCase() ? 'no-project-invitations-remaining-paywall-dialog.upgrade-btn' : 'project-create-dialog.no-project-remaining.list-learn-more'


		const result = await firstValueFrom(
			this.paywallDialogService
				.open({
					...{
						primaryButtonColor: 'accent',
						secondaryButtonColor: 'accent',
						primaryButtonText: this.translateService.instant(
							'no-project-invitations-remaining-paywall-dialog.calendly-btn',
						),
						secondaryButtonText: this.translateService.instant(
							secondaryButtonText
						),
						primaryButtonValue: 'open-calendly',
						secondaryButtonValue: 'open-upgrade',
						showSecondaryButton: true,
					},
					...config,
				})
				.afterClosed(),
		);

		if (result === 'open-upgrade') {
			const isOwner = await firstValueFrom(this.store.select(isOwnerOfCompany));
			if (isOwner) {
				await this.router.navigate(['settings/subscription']);
			} else {
				this.linkService.openLinkInNewTab(this.linkService.pricePage);
			}
			this.trackingService.trackEvent(
				new ProjectmembersLimitPaywallSelectedEventBuilder({ context, role }),
			);
		}

		if (result === 'open-calendly') {
			this.calendlyService.openCalendly(CalendlyLinks.NO_REMAINING_PROJECTS);
		}

		return result;
	}

	constructor(
		private readonly linkService: LinkService,
		private readonly router: Router,
		private readonly calendlyService: CalendlyService,
		private readonly translateService: TranslateService,
		private readonly paywallDialogService: PaywallDialogService,
		private readonly store: Store<AppState>,
		private readonly trackingService: TrackingService,
	) { }
}
