<div class="row h-100 cn-sidebar text-center" [class.notification-center-open]="isNotificationCenterOpen$ | async">
	<ul class="col cn-sidebar__nav w-100">
		<li
			(click)="goToHome()"
			class="cn-sidebar__nav-item pointer"
			[ngClass]="{ 'cn-sidebar__nav-item--is-active': isProjectsRouteActive$ | async }"
		>
			<span *ngIf="projectUnreadCount$ | async" class="cn-sidebar__notification ml-2">
				<mat-icon class="ml-1">fiber_manual_record</mat-icon>
			</span>
			<mat-icon svgIcon="sidebar-project"></mat-icon>
			<div class="cn-sidebar__nav-text" data-cy="sidebar-projects">
				{{ 'sidebar.project' | translate }}
			</div>
		</li>
		<li class="cn-sidebar__nav-item pointer" routerLink="/tasks-dashboard"
				routerLinkActive="cn-sidebar__nav-item--is-active">
			<mat-icon>content_paste</mat-icon>
			<div class="cn-sidebar__nav-text" data-cy="sidebar-task-dashboard">
				{{ 'sidebar.tasks' | translate }}
			</div>
		</li>
		<li
			*ngIf="isOwnerOrSupervisor$ | async"
			class="cn-sidebar__nav-item pointer"
			routerLink="/dashboard/time-tracking"
			routerLinkActive="cn-sidebar__nav-item--is-active"
		>
			<mat-icon fontSet="material-icons-outlined">watch_later</mat-icon>
			<div [innerHTML]="'sidebar.time-tracking' | translate" class="cn-sidebar__nav-text"
					 data-cy="sidebar-worktime-dashboard"></div>
			<span *appFeatureFlag="'time_tracking_beta'" class="badge">BETA</span>
		</li>
		<li class="cn-sidebar__nav-item pointer" routerLink="/planning-board"
				routerLinkActive="cn-sidebar__nav-item--is-active">
			<mat-icon svgIcon="sidebar-calendar"></mat-icon>
			<div class="cn-sidebar__nav-text" data-cy="sidebar-planning-board">
				{{ 'sidebar.planning-board' | translate }}
			</div>
		</li>
		<li class="cn-sidebar__nav-item pointer" *appFeatureFlag="'feature_vehicle_management'" routerLink="/vehicle-management"
				routerLinkActive="cn-sidebar__nav-item--is-active">
			<mat-icon>directions_car</mat-icon>
			<div class="cn-sidebar__nav-text" data-cy="sidebar-vehicle-management">
				Vehicle Management
			</div>
		</li>
		<li
			class="cn-sidebar__nav-item pointer"
			routerLink="/external-chat"
			routerLinkActive="cn-sidebar__nav-item--is-active"
		>
			<span *ngIf="externalChatUnreadCount$ | async" class="cn-sidebar__notification ml-2">
				<mat-icon class="ml-1">fiber_manual_record</mat-icon>
			</span>
			<mat-icon svgIcon="external-chat"></mat-icon>
			<div class="cn-sidebar__nav-text" data-cy="sidebar-external-chat">
				{{ 'sidebar.external-chat' | translate }}
			</div>
		</li>
		<li class="cn-sidebar__nav-item pointer notification-center-button" (click)="openNotificationCenter.emit()">
			<span class="cn-sidebar__notification ml-2"
						*ngIf="notificationCenterPollingService.isUnreadNotificationCenterPolling$ | async">
				<mat-icon class="ml-1">fiber_manual_record</mat-icon>
			</span>
			<span>
				<mat-icon [size]="30" fontSet="material-icons-outlined">notifications</mat-icon>
			</span>
			<div class="cn-sidebar__nav-text">{{ 'sidebar.notifications' | translate }}</div>
		</li>

		<li
			class="cn-sidebar__nav-item pointer"
			*ngIf="scheduleDemoService.scheduleDemoSidebarEnabled$ | async"
			routerLink="/schedule-demo"
			routerLinkActive="cn-sidebar__nav-item--is-active"
			(click)="trackScheduledDemoEvent()"
		>
			<span class="cn-sidebar__notification cn-sidebar__notification--demo ml-2">
				<mat-icon class="ml-1">fiber_manual_record</mat-icon>
			</span>
			<mat-icon svgIcon="schedule-demo"></mat-icon>
			<div class="cn-sidebar__nav-text">
				{{ 'schedule-demo.sidebar.icon-text' | translate }}
			</div>
			<span class="badge">{{ 'schedule-demo.sidebar.new-text' | translate }}</span>
		</li>
	</ul>
</div>
