import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { select, Store } from '@ngrx/store';
import {
	isOwnerOfCompany,
	selectAuthLifecycle,
	selectAuthState,
	selectCompany,
	selectIsLoggedIn,
	selectIsNavbarEnabled,
	selectIsSidebarEnabled,
} from '@store/selectors/app.selectors';
import { AuthLifecycle } from '@store/state/auth.state';
import { AppState } from '@store/state/app.state';
import {
	MatLegacyDialog as MatDialog,
	MatLegacyDialogRef as MatDialogRef,
	MatLegacyDialogState as MatDialogState,
} from '@angular/material/legacy-dialog';
import { BehaviorSubject, combineLatest, Subject, of } from 'rxjs';
import {
	distinctUntilChanged,
	distinctUntilKeyChanged,
	filter,
	map,
	take,
	takeUntil,
	defaultIfEmpty,
	catchError,
} from 'rxjs/operators';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { selectAppInitStatus } from '@store/selectors/app-init.selectors';
import { AppInitStatus } from '@store/state/app-init.state';
import { BrowserLanguage, BrowserService } from '@injectables/services/browser.service';
import { TocService } from '@injectables/services/toc.service';
import { AppInitService } from '../../app-init.service';
import { VerifyAddressDialogComponent } from '@modules/shared/dialog/components/verify-address-dialog-component/verify-address-dialog.component';
import { PurchaseStateService } from '@injectables/services/purchase/purchase-state.service';
import { selectPurchaseState } from '@injectables/services/purchase/purchase-state';

enum AppBanner {
	TRIAL = 'trial-banner',
	BROWSER_ERROR = 'browser-error',
	TOC_DECLINED = 'toc-declined',
}

@Component({
	selector: 'app-features',
	templateUrl: './features.component.html',
	styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit, OnDestroy {
	AppBanner = AppBanner;
	AppInitStatus = AppInitStatus;
	authenticated$ = this.store.pipe(select(selectIsLoggedIn));
	isPublicRoute$ = this.router.events.pipe(map(() => this.router.url.startsWith('/publicFolders')));
	showTrialBanner$ = new BehaviorSubject(false);
	isSideBarEnabled$ = this.store.select(selectIsSidebarEnabled);
	isNavbarRoute$ = this.store.select(selectIsNavbarEnabled);
	warningBannerActive$ = new BehaviorSubject(false);
	appInitStatus$ = this.store.select(selectAppInitStatus);
	isMobile$ = this.appInitService.getMobilePlatform();
	trialSubscription$ = this.store.select(selectPurchaseState).pipe(
		map(response => ({
			remainingTrialDays: response.remainingTrialDays,
			hasRunningTrial: response.hasRunningTrial
		})),
		catchError(() => of({ remainingTrialDays: 0, hasRunningTrial: false })),
		defaultIfEmpty({ remainingTrialDays: 0, hasRunningTrial: false })
	);
	activeBanner$ = new BehaviorSubject<AppBanner>(null);
	destroy$ = new Subject();

	private verificationDialog: MatDialogRef<VerifyAddressDialogComponent>;

	constructor(
		private translate: TranslateService,
		@Inject(DOCUMENT) private readonly document: Document,
		private router: Router,
		private store: Store<AppState>,
		public dialog: MatDialog,
		private readonly remoteConfig: RemoteConfig,
		private readonly browserService: BrowserService,
		private readonly appInitService: AppInitService,
		private readonly tocService: TocService,
		private readonly purchaseStateService: PurchaseStateService,
	) {
		this.initBannerSubscription();
		void this.purchaseStateService.refreshPurchaseState();
		setTimeout(() => {
			void this.purchaseStateService.refreshPurchaseState();
		}, 3000);
		const browserLang = this.browserService.getBrowserLanguage();
		translate.use(browserLang);
		translate.setDefaultLang(BrowserLanguage.DE);
		this.initEmailVerificationDialog();
		this.initTrialBannerDismissed();
		void this.setLanguageMomentConfig(browserLang);
	}

	setWarningBannerActive(active: boolean): void {
		this.warningBannerActive$.next(active);
	}

	get isSplashScreenEnabled(): boolean {
		return !!this.remoteConfig.getValue('feature_splash_screen');
	}

	async setLanguageMomentConfig(browserLang: 'de' | 'en'): Promise<void> {
		await this.translate.getTranslation(browserLang).pipe(take(1)).toPromise();
		moment.updateLocale(browserLang, {
			calendar: {
				lastDay: `[ ${this.translate.instant('files-section.calendar.yesterday')} ]`,
				sameDay: `[ ${this.translate.instant('files-section.calendar.today')} ]`,
				nextWeek: 'DD.MM.YYYY',
				lastWeek: 'DD.MM.YYYY',
				sameElse: 'DD.MM.YYYY',
			},
		});
	}

	ngOnInit(): void {
		if (
			/iPhone|iPad|iPod|Android|Mobile/i.test(navigator.userAgent) &&
			this.router.url !== '/register'
		) {
			this.document.body.classList.add('body-mobile');
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
	}

	initEmailVerificationDialog(): void {
		const routingEvents$ = this.router.events.pipe(
			filter((event) => event instanceof RouterEvent),
			map((event) => event as RouterEvent),
			distinctUntilKeyChanged('url'),
		);

		const requiresUnverified$ = this.store.pipe(
			select(selectAuthLifecycle),
			map((lifecycle) => lifecycle === AuthLifecycle.UNVERIFIED_LOGGED_IN),
			distinctUntilChanged(),
		);

		const profile$ = this.store.select(selectAuthState).pipe(
			map((authState) => authState.user),
			filter((user) => !!user),
			distinctUntilKeyChanged('email'),
		);

		combineLatest([requiresUnverified$, profile$, routingEvents$, this.isNavbarRoute$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([requiresVerification, profile, _routingEvent, isNavbarRoute]) => {
				if (!isNavbarRoute) {
					return;
				}
				if (this.verificationDialog && this.verificationDialog.getState() === MatDialogState.OPEN) {
					this.verificationDialog.close();
				}

				if (!requiresVerification) {
					return;
				}

				this.verificationDialog = this.dialog.open(VerifyAddressDialogComponent, {
					data: { email: profile.email },
				});
			});
	}

	initTrialBannerDismissed(): void {
		const isOwner$ = this.store.select(isOwnerOfCompany);
		combineLatest([this.trialSubscription$, this.authenticated$, isOwner$, this.isNavbarRoute$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(async ([trialSubscription, authenticated, isOwner, isNavbarRoute]) => {
				if (!isNavbarRoute) {
					return;
				}
				if (!(authenticated && isOwner && Boolean(trialSubscription.hasRunningTrial))) {
					this.showTrialBanner$.next(false);
					return;
				}

				this.showTrialBanner$.next(true);
			});
	}

	private initBannerSubscription(): void {
		combineLatest([
			this.isNavbarRoute$,
			this.warningBannerActive$,
			this.showTrialBanner$,
			this.store.select(selectCompany), // To subscribe the company changes
			this.authenticated$,
			this.isMobile$,
			this.store.select(isOwnerOfCompany),
		])
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				async ([
					isNavbarRoute,
					isBrowserWarningActive,
					isTrailBanner,
					,
					isAuthenticated,
					isMobile,
					isOwner,
				]) => {
					if (!isNavbarRoute || !isAuthenticated) {
						this.activeBanner$.next(null);
						return;
					}

					const [tocStatus, isTocEnabled] = await Promise.all([
						this.tocService.getCompanyStatus(),
						this.tocService.isFeatureEnabled(),
					]);

					if (isTocEnabled && tocStatus === 'declined' && isOwner && !isMobile) {
						this.activeBanner$.next(AppBanner.TOC_DECLINED);
						return;
					}

					if (isBrowserWarningActive) {
						this.activeBanner$.next(AppBanner.BROWSER_ERROR);
						return;
					}

					if (isTrailBanner && !isMobile) {
						this.activeBanner$.next(AppBanner.TRIAL);
						return;
					}

					this.activeBanner$.next(null);
				},
			);
	}
}
