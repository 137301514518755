import { NgModule } from '@angular/core';
import { ProjectsAreaComponent } from './projects-area.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { MatIconModule } from '@angular/material/icon';
import { CreateProjectComponent } from './components/create-project/create-project.component';
import { ProjectSearchModule } from '@work/project-search/project-search.module';
import { CompanyMemberRoleDirectiveModule } from '../../directives/company-member-role/company-member-role-directive.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DarkOverlayModule } from '../dark-overlay/dark-overlay.module';
import { CustomMatIconsModule } from '../../custom-mat-icons/custom-mat-icons.module';
import { ButtonLoaderModule, NamedIconModule } from '@craftnote/material-theme';
import { SharedProjectsModule } from '../../../features/projects/shared-projects.module';
import { MatIconSizeModule } from '../../directives/mat-icon-size.module';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { IsNilPipeModule } from '../../pipes/is-nil/is-nil-pipe.module';
import { ProjectAddressPipeModule } from '../../pipes/project-address/project-address-pipe.module';
import { ProjectItemWrapperComponent } from './components/project-item-wrapper/project-item-wrapper.component';
import { ProjectItemNewComponent } from './components/project-item-new/project-item-new.component';
import {
	EventStopPropagationModule,
	NgVarModule,
	SharedModule,
} from '@craftnote/shared-angular-modules';
import { ProjectStatusProgressBarComponent } from './components/project-status-progress-bar/project-status-progress-bar.component';
import { PaywallDialogModule } from '@modules/shared/components/paywall-dialog/paywall-dialog.module';
import { ProjectArchivationNudgeComponent } from './components/project-archivation-nudge/project-archivation-nudge.component';
import { ProjectArchivationDialogComponent } from './components/project-archivation-dialog/project-archivation-dialog.component';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { FailedUploadsNudgeComponent } from '@modules/shared/components/projects-area/components/failed-uploads-nudge/failed-uploads-nudge.component';
import { RouterLink } from '@angular/router';
import { ProjectListNudgeComponent } from '@modules/shared/components/projects-area/components/project-list-nudge/project-list-nudge.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
	declarations: [
		ProjectsAreaComponent,
		ProjectsListComponent,
		CreateProjectComponent,
		ProjectItemWrapperComponent,
		ProjectItemNewComponent,
		ProjectStatusProgressBarComponent,
		ProjectArchivationNudgeComponent,
		ProjectArchivationDialogComponent,
		FailedUploadsNudgeComponent,
		ProjectListNudgeComponent,
	],
	imports: [
		SharedModule,
		ProjectSearchModule,
		CompanyMemberRoleDirectiveModule,
		ScrollingModule,
		DarkOverlayModule,
		MatButtonModule,
		CustomMatIconsModule,
		NamedIconModule,
		SharedProjectsModule,
		MatIconSizeModule,
		MatMenuModule,
		MatIconModule,
		MatProgressBarModule,
		IsNilPipeModule,
		ProjectAddressPipeModule,
		PaywallDialogModule,
		EventStopPropagationModule,
		NgVarModule,
		MatLegacyCheckboxModule,
		MatDialogModule,
		ButtonLoaderModule,
		RouterLink,
		MatDividerModule,
	],
	exports: [ProjectsAreaComponent],
})
export class ProjectsAreaModule { }
