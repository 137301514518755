import { NgModule } from '@angular/core';
import { authReducer } from './reducers/auth.reducer';
import { companyReducer } from './reducers/company.reducer';
import * as companySettingsReducers from './reducers/company-settings.reducer';
import { appVersionReducer } from './reducers/application-version.reducer';
import { appReducer } from './reducers/app.reducer';
import {
	NavigationActionTiming,
	routerReducer,
	StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { exportReducer } from './reducers/export.reducer';
import { companyFileReducer } from './reducers/company-files.reducer';
import { profileLimitsReducer } from './reducers/profile-limits.reducer';
import { tradeOptionsReducer } from './reducers/trade-options.reducer';
import { projectReducer } from './reducers/project/projects.reducer';
import { projectFilesReducer } from './reducers/project-files.reducer';
import { profileTrackerReducer } from './reducers/profile-tracker.reducer';
import { externalChatReducer } from './reducers/external-chat.reducer';
import { companyProjectsReducer } from './reducers/company-projects.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './effects/app.effects';
import { AuthEffects } from './effects/auth.effect';
import { ProfileEffects } from './effects/profile.effects';
import { ProjectsEffects } from './effects/projects.effects';
import { ProjectFilesEffects } from './effects/project-files.effects';
import { CompanyEffects } from './effects/company.effects';
import { CompanySettingsEffects } from './effects/company-settings.effects';
import { CompanyFilesEffects } from './effects/company-files.effects';
import { CurrentUserProfileLimitsEffect } from './effects/current-user-profile-limits.effect';
import { CompanyMembersProfileLimitsEffect } from './effects/company-members-profile-limits.effect';
import { TradeOptionsEffects } from './effects/trade-options.effects';
import { NavigationEffects } from './effects/navigation.effects';
import { ProfileTrackerEffects } from './effects/profile-tracker.effects';
import { ExternalChatUserStateEffects } from './effects/external-chat-user-state.effects';
import { CompanyProjectsEffect } from './effects/company-projects.effect';
import { invitationsReducer } from './reducers/invitations.reducer';
import { InvitationsEffects } from './effects/invitations.effects';
import { BrowserEffects } from './effects/browser.effects';
import { browserReducer } from './reducers/browser.reducer';
import { appInitReducer } from './reducers/app-init.reducer';
import { ExportEffect } from './effects/export.effect';
import { PaywallHeaderDialogEffects } from './effects/paywall-header-dialog.effects';
import { AppInitEffects } from './effects/app-init.effects';
import { NotificationInfoEffects } from './effects/notification-info-effects';
import { deviceReducer } from './reducers/device.reducer';
import { ClickIdEffects } from './effects/click-id.effect';
import { ProjectUnreadsEffects } from '@store/effects/project-unreads.effects';
import { projectUnreadsReducer } from '@store/reducers/project-unreads.reducer';
import { CompanyMetadataEffects } from '@store/effects/company-metadata.effects';
import { companyMetadataReducer } from '@store/reducers/company-metadata.reducer';
import { WindowVisibilityEffects } from '@store/effects/window-visibility.effects';
import { CompanyTasksEffect } from '@store/effects/company-tasks.effect';
import { companyTasksReducer } from '@store/reducers/tasks.reducer';
import { reducerFileUpload } from '@store/reducers/file-upload.reducer';
import { purchaseStateReducer } from '@injectables/services/purchase/purchase-state';

@NgModule({
	imports: [
		StoreModule.forRoot({
			appInit: appInitReducer,
			authState: authReducer,
			companyState: companyReducer,
			projectStatus: companySettingsReducers.companySettingsReducer,
			workTypes: companySettingsReducers.workTypesReducer,
			version: appVersionReducer,
			appUiState: appReducer,
			router: routerReducer,
			exportState: exportReducer,
			companyFiles: companyFileReducer,
			profileLimits: profileLimitsReducer,
			tradeOptions: tradeOptionsReducer,
			projects: projectReducer,
			projectFiles: projectFilesReducer,
			profileTrackers: profileTrackerReducer,
			externalChat: externalChatReducer,
			companyProjects: companyProjectsReducer,
			invitationsState: invitationsReducer,
			browserState: browserReducer,
			deviceState: deviceReducer,
			projectUnreads: projectUnreadsReducer,
			companyMetadata: companyMetadataReducer,
			companyTasks: companyTasksReducer,
			fileUpload: reducerFileUpload,
			purchaseState: purchaseStateReducer,
		}),
		EffectsModule.forRoot([
			AppEffects,
			AuthEffects,
			ProfileEffects,
			ProjectsEffects,
			ProjectFilesEffects,
			CompanyEffects,
			CompanySettingsEffects,
			CompanyFilesEffects,
			CurrentUserProfileLimitsEffect,
			CompanyMembersProfileLimitsEffect,
			TradeOptionsEffects,
			CompanyMembersProfileLimitsEffect,
			NavigationEffects,
			ProfileTrackerEffects,
			ExternalChatUserStateEffects,
			CompanyProjectsEffect,
			InvitationsEffects,
			BrowserEffects,
			ExportEffect,
			AppInitEffects,
			PaywallHeaderDialogEffects,
			NotificationInfoEffects,
			ClickIdEffects,
			ProjectUnreadsEffects,
			WindowVisibilityEffects,
			CompanyMetadataEffects,
			CompanyTasksEffect,
		]),
		StoreRouterConnectingModule.forRoot({
			navigationActionTiming: NavigationActionTiming.PostActivation,
		}),
	],
	exports: [StoreModule, StoreRouterConnectingModule],
})
export class AppStoreModule { }
