import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import {
	SharedSliderDialogCustomViewSlide,
	SharedSliderDialogData,
	SharedSliderDialogSelectionSlide,
	SharedSliderDialogSideType,
	SharedSliderDialogTextSlide,
	SharedSliderDialogToggleOption,
	SharedSliderDialogToggleSlide,
} from '@modules/shared/components/shared-slider-dialog/entities/shared-slider-dialog.data';
import { CompanyService } from '@injectables/services/company/company.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { isOwnerOfCompany, selectCompany } from '@store/selectors/app.selectors';
import { filter, take } from 'rxjs/operators';
import { selectUserTradeOptions } from '@store/selectors/trade-options.selector';
import { initTradeOptionsActions } from '@store/actions/trade-options.actions';
import { COMPANY_SIZE_DEFAULTS } from '@shared/constants/company-size.constants';
import { AlertService } from '@injectables/services/alert/alert.service';
import { initializeCompanyFactory } from '@shared/firebase/company/company-update.functions';
import { StepCompletedEvent } from '@modules/shared/components/shared-slider-dialog/components/shared-slider-dialog/shared-slider-dialog.component';
import { ProjectHelperService } from '@injectables/services/project-helper.service';
import { EXAMPLE_PROJECT_NAMES } from '@injectables/services/project/project.service';
import { RemoteConfig } from '@injectables/services/remote-config/remote-config.service';
import { TrackingService } from '@injectables/services/tracking.service';
import {
	CompanyprofileInitClosedEventBuilder,
	CompanyprofileInitCreatedEventBuilder,
} from '@generated/events/CompanyprofileEvents.generated';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CompanyMemberOperation } from '@modules/shared/components/add-edit-company-employee/add-edit-company-employee.component';
import { ConfirmDialogService } from '@craftnote/material-theme';
import { TranslateService } from '@ngx-translate/core';
import { REGISTRATION_PHONE_NUMBER_PATTERN } from '@constants/validators.constants';
import { Validators } from '@angular/forms';
import { PurchaseStateService } from '@injectables/services/purchase/purchase-state.service';
@Component({
	selector: 'app-company-initialization',
	templateUrl: './company-initialization.component.html',
	styleUrls: ['./company-initialization.component.scss'],
})
export class CompanyInitializationComponent {
	@Output() completed = new EventEmitter<boolean>();
	@ViewChild('addEditCompanyEmployeeTemplate') addEditCompanyEmployeeTemplate: TemplateRef<any>;
	CompanyMemberOperation = CompanyMemberOperation;
	companyMemberInvitedInRegistrationFlow: 'invited' | 'skipped' = null;
	bulkInviteNumberOfUsersInvited = 0;
	bulkInviteFormValid = false;

	data: SharedSliderDialogData;
	hideCancelCompanyInit$ = this.remoteConfig.getValueAsync(
		'feature_hide_company_init_cancellation',
	);
	private currentStep$ = new BehaviorSubject<number>(0);

	constructor(
		private readonly companyService: CompanyService,
		private readonly alertService: AlertService,
		private readonly store: Store<AppState>,
		private readonly projectHelperService: ProjectHelperService,
		private readonly remoteConfig: RemoteConfig,
		private readonly trackingService: TrackingService,
		private readonly confirmDialogService: ConfirmDialogService,
		private readonly translateService: TranslateService,
		private readonly purchaseStateService: PurchaseStateService,
	) {
		this.initData();
	}

	get isSplashScreenEnabled(): boolean {
		return !!this.remoteConfig.getValue('feature_splash_screen');
	}

	async initData(): Promise<void> {
		this.store.dispatch(initTradeOptionsActions());
		const trades = await this.store
			.select(selectUserTradeOptions)
			.pipe(
				filter((options) => options !== undefined),
				take(1),
			)
			.toPromise();
		const company = await firstValueFrom(
			this.store.select(selectCompany).pipe(filter((companyInner) => !!companyInner?.id)),
		);
		const companyNameSlide: SharedSliderDialogTextSlide = {
			type: SharedSliderDialogSideType.TEXT,
			titleKey: 'company-init.company-name.title',
			subtitleKey: 'company-init.company-name.subtitle',
			actionKey: 'company-init.actions.next',
			labelKey: 'company-init.company-name.label',
		};

		const companyPhoneNumberSlide: SharedSliderDialogTextSlide = {
			type: SharedSliderDialogSideType.TEXT,
			titleKey: 'company-init.company-phone-number.title',
			subtitleKey: 'company-init.company-phone-number.subtitle',
			actionKey: 'company-init.actions.next',
			labelKey: 'company-init.company-phone-number.label',
			validation: {
				validators: [Validators.pattern(REGISTRATION_PHONE_NUMBER_PATTERN)],
				errorKeysByError: { pattern: 'global.errors.invalid-phone-pattern' },
			},
			value: company.phone,
		};

		const toggleSlide: SharedSliderDialogToggleSlide = {
			type: SharedSliderDialogSideType.TOGGLE,
			titleKey: 'company-init.company-size.title',
			subtitleKey: 'company-init.company-size.subtitle',
			actionKey: 'company-init.actions.next',
			toggles: this.generateToggleOptions(),
		};

		const selectionSlide: SharedSliderDialogSelectionSlide = {
			type: SharedSliderDialogSideType.SELECTION,
			titleKey: 'company-init.company-trade.title',
			subtitleKey: 'company-init.company-trade.subtitle',
			actionKey: 'company-init.actions.next',
			labelKey: 'company-init.company-trade.label',
			selectionOptions: trades,
		};

		const inviteCompanyMemberSlide: SharedSliderDialogCustomViewSlide = {
			titleKey: 'company-init.invite-company-members.title-bulk',
			type: SharedSliderDialogSideType.CUSTOM_VIEW,
			customViewTemplateRef: this.addEditCompanyEmployeeTemplate,
		};

		this.data = [
			companyNameSlide,
			companyPhoneNumberSlide,
			toggleSlide,
			selectionSlide,
			inviteCompanyMemberSlide,
		];
	}

	async onComplete(values: unknown[]): Promise<void> {
		const promises: Promise<void>[] = [];
		promises.push(this.exit());

		const company = await this.store
			.select(selectCompany)
			.pipe(
				filter((comp) => !!comp),
				take(1),
			)
			.toPromise();
		const isOwner = await this.store.select(isOwnerOfCompany).pipe(take(1)).toPromise();
		/**
		 * If the company already has a trade then it was already initialized. In this case we discard the info.
		 * If splashscreen is enabled any member can update the details from company - init screen
		 */
		if ((isOwner && !company.trade) || this.isSplashScreenEnabled) {
			const [companyName, phoneNumber, companySize, trade] = values;
			const initFunction = initializeCompanyFactory(
				companyName as string,
				phoneNumber as string,
				trade as string,
				COMPANY_SIZE_DEFAULTS[companySize as number],
			);
			promises.push(this.companyService.updateCompanyTransactional(company.id, initFunction));
		}
		await Promise.all(promises);
	}

	async onStepCompleted(value: StepCompletedEvent): Promise<void> {
		this.currentStep$.next(value.currentIndex + 1);

		if (value.currentIndex === 3) {
			await this.trackingService.trackEvent(
				new CompanyprofileInitCreatedEventBuilder({
					lastAction: this.companyMemberInvitedInRegistrationFlow,
					invitedMemberCount: this.bulkInviteNumberOfUsersInvited,
				}),
			);
		}
	}

	async onRegistrationFlowNextButtonPressed(
		onNext: EventEmitter<void>,
		companyMemberInvitedInRegistrationFlowLastAction: 'invited' | 'skipped',
		isBulkInviteEnabled: boolean,
		bulkInviteNumberOfUsersInvited: number,
	): Promise<void> {
		if (
			companyMemberInvitedInRegistrationFlowLastAction === 'skipped' &&
			this.bulkInviteFormValid &&
			isBulkInviteEnabled &&
			(await this.confirmDialogService
				.open({
					title: this.translateService.instant('company-initialization.bulk-invite-cancel'),
					primaryButtonText: this.translateService.instant(
						'company-initialization.bulk-invite-cancel.yes',
					),
					secondaryButtonText: this.translateService.instant(
						'company-initialization.bulk-invite-cancel.no',
					),
					primaryButtonValue: false,
					secondaryButtonValue: true,
					secondaryButtonColor: 'warn',
					showCrossBtn: false,
				})
				.afterClosed()
				.pipe(take(1))
				.toPromise())
		) {
			return;
		}

		this.companyMemberInvitedInRegistrationFlow = companyMemberInvitedInRegistrationFlowLastAction;
		this.bulkInviteNumberOfUsersInvited = bulkInviteNumberOfUsersInvited;
		onNext.emit();
	}

	async close(): Promise<void> {
		this.exitFromCompanyInit();
		await this.trackingService.trackEvent(
			new CompanyprofileInitClosedEventBuilder({
				step: this.getStepName(this.currentStep$.value),
			}),
		);
	}

	isBulkInviteFormValid(isFormValid: boolean): void {
		this.bulkInviteFormValid = isFormValid;
	}

	private getStepName(index: number): string {
		switch (index) {
			case 0:
				return 'name';
			case 1:
				return 'size';
			case 2:
				return 'trade';
		}
	}

	private generateToggleOptions(): SharedSliderDialogToggleOption[] {
		return COMPANY_SIZE_DEFAULTS.map((size, index) => {
			const imageSource = `assets/images/icons/company-initialization/group-${index + 1}.svg`;
			let caption: string;
			if (size.to === undefined) {
				caption = `${size.from}+`;
			} else {
				caption = `${size.from}-${size.to}`;
			}
			return { imageSource, caption };
		});
	}

	private exitFromCompanyInit(): void {
		void this.purchaseStateService.refreshPurchaseState();
		setTimeout(() => {
			void this.purchaseStateService.refreshPurchaseState();
		}, 3000);
		if (this.isSplashScreenEnabled) {
			this.completed.emit(true);
		} else {
			void this.projectHelperService.goToHome(EXAMPLE_PROJECT_NAMES[0]);
		}
	}

	private async exit(): Promise<void> {
		this.alertService.showAlert('company-init.alerts.success');
		this.exitFromCompanyInit();
	}
}
