<ng-container *ngIf="(selectProjectFiles$ | async)?.length > 0; else emptyFolder">
	<div
		cdkDropList
		class="drag-files-list"
		[ngClass]="{ 'd-flex text-center justify-content-between flex-wrap': viewOption === 'grid' }"
		dragAndDropManagerRoot
		cdkDropListSortingDisabled
		[cdkDropListData]="selectProjectFiles$ | async"
	>
		<div
			cdkDrag
			appEventStopPropagation
			*ngFor="let file of selectProjectFiles$ | async"
			[ngClass]="{
				'files-section--list-item': viewOption === 'list',
				'files-section--grid-item': viewOption === 'grid',
				'item-selected': selectedFiles[file?.id]?.id === file?.id
			}"
			class="files-section drag-file-item"
			data-cy="file-section-item1"
			(mouseenter)="onMouseEnter(file)"
			(mouseleave)="onMouseLeave()"
			(click)="selectFileOnClick(file, $event)"
			[cdkDragDisabled]="selectedFiles[file?.id]?.id !== file?.id"
			(cdkDragStarted)="onDragStarted()"
			(cdkDragEnded)="onDragEnded()"
		>
			<div *cdkDragPreview>
				<div
					class="drag-preview float-left d-flex align-items-center justify-content-center"
					[matBadge]="selectedFilesLength"
					matBadgeColor="accent"
					matBadgePosition="after"
				>
					<mat-icon class="mr-2" [svgIcon]="'files-folder' + (themeSuffix$ | async)"></mat-icon>
					Move items
				</div>
			</div>

			<div
				dragAndDropManager
				cdkDropList
				class="drag-files-list folders-placeholder"
				[id]="file.id"
				*ngIf="file.fileType === FILE_TYPE.FOLDER"
				[cdkDropListData]="getFilesInFolder(file)"
				[cdkDropListEnterPredicate]="noReturnPredicate"
				[ignoreDestinationItems]="ignoreDestinationFolders"
				(mouseup)="selectedFiles[file?.id]?.id !== file?.id && onDropFiles(file)"
			>
				<div
					[cdkDrag]="file"
					[ngTemplateOutletContext]="{ file: file }"
					[ngTemplateOutlet]="fileTypeFolder"
					class="drag-file-item"
					data-cy="file-section-item2"
					[cdkDragDisabled]="file.fileCategory === 'company-file' || selectedFiles[file?.id]?.id !== file?.id"
				></div>
			</div>
			<ng-container
				*ngIf="file.fileType !== FILE_TYPE.FOLDER"
				[ngTemplateOutletContext]="{ file: file }"
				[ngTemplateOutlet]="fileTypeNotFolder"
			></ng-container>
		</div>
	</div>
</ng-container>

<ng-template #fileTypeFolder let-file="file">
	<div
		(click)="selectFolder(file)"
		class="file-section-item d-flex justify-content-between h-100"
		data-cy="file-section-folder-item"
		[ngClass]="{
			'd-flex flex-grow-1': viewOption === 'list',
			'flex-column': viewOption === 'grid',
			'destination-drop-folder': isDragStarted && file.fileCategory !== 'company-file' && selectedFiles[file?.id]?.id !== file?.id
		}"
	>
		<div class="files-section__permissions">
			<ng-container
				[ngTemplateOutletContext]="{ file: file, isPermissionDots: isPermissionDotsEnabled$(file) | async }"
				[ngTemplateOutlet]="permissionDots"
			></ng-container>
		</div>
		<div class="files-section__icon">
			<mat-icon *ngIf="file.fileCategory === 'company-file'" [svgIcon]="'files-folder-company' + (themeSuffix$ | async)"></mat-icon>
			<mat-icon *ngIf="file.fileCategory === 'shared-file'" [svgIcon]="'files-folder-shared' + (themeSuffix$ | async)"></mat-icon>
			<mat-icon *ngIf="file.fileCategory === 'project-file'" [svgIcon]="'files-folder' + (themeSuffix$ | async)"></mat-icon>
		</div>
		<div [ngClass]="{ 'with-permission-dots': isPermissionDotsEnabled$(file) | async }" class="files-section__content">
			<div class="files-section__name" data-cy="files-folder-name">
				{{ file.name }}
			</div>
			<div class="files-section__info">
				<span *ngIf="file.createdTimestamp"> {{ file.createdTimestamp | readableDate }} - </span>
				<span>{{ file.noOfFiles }} {{ 'files-section.files' | translate }} </span>
			</div>
		</div>
		<div *ngIf="file.fileCategory !== 'company-file' && !filesOperation" class="files-section__actions d-flex justify-content-between pl-1">
			<span
				(click)="selectFile(file, $event)"
				*ngIf="selectedFiles[file?.id]?.id === file?.id || file?.id === fileHoveredId || selectedFilesLength > 0"
				class="pointer"
			>
				<ng-container
					[ngTemplateOutletContext]="{ file: file, selectedFilesLength: selectedFilesLength, selectedFiles: selectedFiles }"
					[ngTemplateOutlet]="checkBox"
				></ng-container>
			</span>
			<ng-container [ngTemplateOutletContext]="{ file: file }" [ngTemplateOutlet]="folderMenu"></ng-container>
		</div>
	</div>
</ng-template>

<ng-template #fileTypeNotFolder let-file="file">
	<div
		class="file-section-item d-flex justify-content-between h-100"
		[ngClass]="{ 'flex-grow-1': viewOption === 'list', 'flex-column': viewOption === 'grid' }"
		data-cy="file-section-item"
	>
		<div class="files-section__icon icon-file" (click)="openSingleFile(file)">
			<app-files-section-icon [file]="file" [gridView]="viewOption === 'grid'"></app-files-section-icon>
		</div>
		<div class="files-section__content" (click)="openSingleFile(file)">
			<div class="files-section__name">
				{{ file.name }}
			</div>
			<div class="files-section__info">
				<span *ngIf="file.createdTimestamp">{{ file.createdTimestamp | readableDate }} - </span>
				<span>{{ file.fileSize | bytes }}</span>
			</div>
		</div>
		<div
			*ngIf="file.fileCategory !== 'company-file'"
			class="files-section__actions d-flex justify-content-between pl-1"
			data-cy="file-item-action"
		>
			<span
				(click)="selectFile(file, $event)"
				*ngIf="selectedFiles[file?.id]?.id === file?.id || file?.id === fileHoveredId || selectedFilesLength > 0"
				class="pointer"
			>
				<ng-container
					[ngTemplateOutletContext]="{ file: file, selectedFilesLength: selectedFilesLength, selectedFiles: selectedFiles }"
					[ngTemplateOutlet]="checkBox"
				></ng-container>
			</span>
			<ng-container [ngTemplateOutletContext]="{ file: file }" [ngTemplateOutlet]="menu"></ng-container>
		</div>
	</div>
</ng-template>

<ng-template #menu let-file="file">
	<ng-container *ngIf="selectedFilesLength === 0 || selectedFiles[file?.id]?.id === file?.id">
		<mat-icon
			appEventStopPropagation
			[matMenuTriggerFor]="fileMenu"
			class="pointer"
			[ngClass]="{ 'ml-auto': viewOption === 'grid' }"
			mat-button
			>{{ viewOption === 'grid' ? 'more_horiz' : 'more_vert' }}</mat-icon
		>
	</ng-container>
	<mat-menu #fileMenu="matMenu" class="craftnote-menu with-icon">
		<ng-container [ngTemplateOutlet]="downloadButton" [ngTemplateOutletContext]="{ file: file }"></ng-container>
		<button (click)="renameFileOrFolder(file)" data-cy="rename-files-in-files" mat-menu-item>
			<mat-icon svgIcon="create"></mat-icon>
			{{ 'files-section.menu.rename' | translate }}
		</button>
		<button (click)="moveWithInProject(file)" class="file-section-menu__item" mat-menu-item>
			<mat-icon svgIcon="move" data-cy="move-files-in-files-section"></mat-icon>
			{{ (selectedFilesLength > 1 ? 'files-section.menu.moveAll' : 'files-section.menu.move') | translate }}
		</button>
		<button
			(click)="copyWithInProject(file)"
			class="file-section-menu__item"
			*ngIf="!isOneOfFolderSelected"
			data-cy="copy-files-in-files"
			mat-menu-item
		>
			<mat-icon svgIcon="reply"></mat-icon>
			{{ 'files-section.menu.copy-with-in-project' | translate }}
		</button>
		<button (click)="copyToChat(file)" *ngIf="!isOneOfFolderSelected" data-cy="copy-to-chat-same-project" mat-menu-item>
			<mat-icon svgIcon="reply"></mat-icon>
			{{ 'files-section.menu.copy-to-chat' | translate }}
		</button>
		<button
			(click)="copyToOtherProject(file)"
			class="file-section-menu__item"
			*ngIf="!isOneOfFolderSelected"
			data-cy="copy-to-files-other-project"
			mat-menu-item
		>
			<mat-icon svgIcon="reply"></mat-icon>
			{{ 'files-section.menu.copy-to-other-project' | translate }}
		</button>
		<button (click)="copyToChatOtherProject(file)" *ngIf="!isOneOfFolderSelected" data-cy="copy-to-chat-other-project" mat-menu-item>
			<mat-icon svgIcon="reply"></mat-icon>
			{{ 'files-section.menu.copy-to-chat-other-project' | translate }}
		</button>
		<button (click)="deleteElement(file)" *ngIf="canDelete && file.canDelete" class="file-section-menu__item" mat-menu-item>
			<mat-icon svgIcon="delete" data-cy="file-item-delete"></mat-icon>
			{{ (selectedFilesLength > 1 ? 'files-section.menu.deleteAll' : 'files-section.menu.delete') | translate }}
		</button>
	</mat-menu>
</ng-template>

<ng-template #folderMenu let-file="file">
	<ng-container *ngIf="selectedFilesLength === 0 || selectedFiles[file?.id]?.id === file?.id">
		<mat-icon
			appEventStopPropagation
			[matMenuTriggerFor]="fileMenu"
			class="pointer"
			data-cy="folder-file-actions"
			[ngClass]="{ 'ml-auto': viewOption === 'grid' }"
			mat-button
			>{{ viewOption === 'grid' ? 'more_horiz' : 'more_vert' }}</mat-icon
		>
	</ng-container>
	<mat-menu #fileMenu="matMenu" class="craftnote-menu with-icon">
		<ng-container [ngTemplateOutlet]="downloadButton" [ngTemplateOutletContext]="{ file: file }"></ng-container>
		<button (click)="renameFileOrFolder(file)" data-cy="rename-folder-in-files" mat-menu-item>
			<mat-icon svgIcon="create"></mat-icon>
			{{ 'files-section.menu.rename' | translate }}
		</button>
		<button (click)="moveWithInProject(file)" class="file-section-menu__item" mat-menu-item>
			<mat-icon svgIcon="move" data-cy="move-folder-in-files-section"></mat-icon>
			{{ (selectedFilesLength > 1 ? 'files-section.menu.moveAll' : 'files-section.menu.move') | translate }}
		</button>
		<button
			(click)="manageFileAccess(file)"
			*ngIf="!file.parentId && (isOwnerAndMemberOfProject$ | async)"
			class="file-section-menu__item"
			data-cy="manage-access-folders"
			mat-menu-item
		>
			<mat-icon svgIcon="lock-open"></mat-icon>
			{{ 'files-section.menu.manage-access' | translate }}
		</button>
		<button (click)="deleteElement(file)" *ngIf="canDelete && file.canDelete" class="file-section-menu__item" mat-menu-item>
			<mat-icon svgIcon="delete" data-cy="delete-folders-in-files-section"></mat-icon>
			{{ (selectedFilesLength > 1 ? 'files-section.menu.deleteAll' : 'files-section.menu.delete') | translate }}
		</button>
	</mat-menu>
</ng-template>

<ng-template #downloadButton let-file="file">
	<button
		*ngIf="isOwnerOrInternalSupervisorOfProject$ | async"
		class="file-section-menu__item color-primary d-flex align-items-center"
		(click)="download.emit(file)"
		mat-menu-item
	>
		<mat-icon class="color-primary">download</mat-icon>
		<span *ngIf="selectedFilesLength <= 1; else downloadAll">
			{{ 'files-section.menu.download' | translate }}
			<span class="badge badge-dark premium-badge" *ngIf="!(canDownloadMultipleFiles$ | async) && file.fileType === FILE_TYPE.FOLDER">{{
				PRO_PREMIUM_BADGE
			}}</span>
		</span>
		<ng-template #downloadAll>
			{{ 'files-section.menu.downloadAll' | translate: { filesSelected: selectedFilesLength } }}
			<span class="badge badge-dark premium-badge" *ngIf="!(canMultiDownloadDownload$ | async)">{{ PRO_PREMIUM_BADGE }}</span>
		</ng-template>
	</button>
</ng-template>

<ng-template #checkBox let-file="file" let-selectedFilesLength="selectedFilesLength" , let-selectedFiles="selectedFiles">
	<mat-checkbox
		[ngClass]="{ 'pr-4': selectedFilesLength > 0 && selectedFiles[file?.id]?.id !== file?.id }"
		[checked]="!!selectedFiles[file?.id]"
		class="no-events"
	></mat-checkbox>
</ng-template>

<ng-template #permissionDots let-element="file" let-isPermissionDots="isPermissionDots">
	<div *ngIf="isPermissionDots" class="d-flex flex-column file-permissions-dots">
		<mat-icon inline="true">{{ isFolderAccessibleByRole(element, MEMBER_ROLE.SUPERVISOR) ? 'lens' : 'panorama_fish_eye' }}</mat-icon>
		<mat-icon inline="true">{{ isFolderAccessibleByRole(element, MEMBER_ROLE.EMPLOYEE) ? 'lens' : 'panorama_fish_eye' }}</mat-icon>
		<mat-icon inline="true">{{ isFolderAccessibleByRole(element, MEMBER_ROLE.EXTERNAL) ? 'lens' : 'panorama_fish_eye' }}</mat-icon>
	</div>
</ng-template>

<ng-template #emptyFolder>
	<div class="files-section__empty-container d-flex flex-column align-items-center justify-content-center p-4">
		<div>
			<mat-icon class="mb-2" svgIcon="empty-folder"></mat-icon>
			<div class="font-weight-normal">
				{{ 'files-section.folders.empty-folder' | translate }}
			</div>
		</div>
	</div>
</ng-template>
