import { createAction, createFeatureSelector, createReducer, on, props } from "@ngrx/store";
import { PurchaseState } from "./purchase.service";

export const selectPurchaseState = createFeatureSelector<PurchaseState>('purchaseState');

export const setPurchaseStateAction = createAction(
	'[Purchase] Set purchaseState',
	props<{ purchaseState: PurchaseState | undefined }>(),
);

export const optimimisticForceTrialEndedAction = createAction(
	'[Purchase] Optimimistic force trial ended',
);

const initialState: PurchaseState = {
	hasRunningTrial: false,
	remainingTrialDays: 0,
};

export const purchaseStateReducer = createReducer(
	initialState,
	on(setPurchaseStateAction, (state, { purchaseState }) => {
		return {
			...state,
			...purchaseState,
		};
	}),
	on(optimimisticForceTrialEndedAction, (state) => {
		return {
			...state,
			hasRunningTrial: false,
		};
	}),
);
