import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { BrowserDetectionService } from '@injectables/services/browser-detection.service';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ScriptLoaderService } from '@injectables/services/script-loader.service';

@Component({
	selector: 'app-custom-web-component',
	templateUrl: './custom-web.component.html',
	styleUrls: ['./custom-web.component.scss'],
})
export class CustomWebComponent implements AfterViewInit {
	@ViewChild('componentContainer') element: ElementRef;
	showLoader = true;

	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly browserDetectionService: BrowserDetectionService,
		private readonly render: Renderer2,
		private readonly functions: AngularFireFunctions,
		private readonly scriptLoaderService: ScriptLoaderService,
	) {}

	get isCompatibleWithCustomComponents(): boolean {
		return this.browserDetectionService.isCompatibleWithCustomElements();
	}

	ngAfterViewInit(): void {
		void this.appendWebComponent();
	}

	private async appendWebComponent(): Promise<void> {
		const { componentUrl, componentTag } = this.activatedRoute.snapshot.data;

		if (this.isCompatibleWithCustomComponents) {
			await this.addExternalChatWebComponent(componentUrl, componentTag);
			return this.appendChildElement(componentTag);
		}
	}

	private async getVersion(remoteUrl: string): Promise<number> {
		try {
			const now = moment().valueOf();
			const response = await fetch(`${remoteUrl}/static/version.json?t=${now}`).then((res) =>
				res.json(),
			);
			return response.version;
		} catch (error) {
			return 0;
		}
	}

	private async addExternalChatWebComponent(
		componentUrl: string,
		componentTag: string,
	): Promise<void> {
		if (customElements.get(componentTag)) {
			return;
		}
		const version = await this.getVersion(componentUrl);
		await this.scriptLoaderService.loadScript(
			componentTag,
			`${componentUrl}/static/js/main.js?v=${version}`,
		);
	}

	private async appendChildElement(componentTag: string): Promise<void> {
		const webComponent = this.render.createElement(componentTag);
		const { customToken } = await this.functions
			.httpsCallable('createCustomToken')(null)
			.toPromise();
		this.render.setAttribute(webComponent, 'user-token', customToken);
		this.render.addClass(webComponent, 'custom-web-component');

		const organizationId = this.activatedRoute.snapshot.paramMap.get('organization-id');
		if (organizationId) {
			this.render.setAttribute(webComponent, 'organization-id-for-app-init', organizationId);
		}

		this.showLoader = false;
		this.render.appendChild(this.element.nativeElement, webComponent);
	}
}
