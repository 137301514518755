import { Injectable } from '@angular/core';
import { combineLatest, of, firstValueFrom } from 'rxjs';
import {
	isOwnerOrSupervisorOfCompany,
	selectCompanyMembers,
	selectCompanySize,
	selectCompanySizeHumanize,
	selectCompanyTrade,
	selectProfile,
	selectUserMobile,
} from '@store/selectors/app.selectors';
import { filter, map, take, catchError } from 'rxjs/operators';
import { CompanySize } from '@shared/models/company-size.type';
import { companySizeInRange } from '@shared/functions/company/company.functions';
import { Store } from '@ngrx/store';
import { AppState } from '@store/state/app.state';
import { MemberFullNamePipe } from '@modules/shared/pipes/member-full-name/member-full-name.pipe';
import { selectPurchaseState } from '@injectables/services/purchase/purchase-state';
declare var Calendly: any;

@Injectable({
	providedIn: 'root',
})
export class ScheduleDemoService {
	private scheduleDemoEnabled$ = combineLatest([
		this.store.select(selectCompanySize).pipe(filter<CompanySize>(Boolean)),
		this.store.select(selectPurchaseState).pipe(
			map(response => response.hasRunningTrial),
			catchError(() => of(false))
		),
		this.store.select<boolean>(isOwnerOrSupervisorOfCompany),
	]).pipe(
		map(([companySize, hasRunningTrial, isOwnerOrSupervisor]) => {
			return (
				hasRunningTrial &&
				companySizeInRange(companySize, { from: 9 }) &&
				isOwnerOrSupervisor
			);
		}),
	);

	scheduleDemoSidebarEnabled$ = this.scheduleDemoEnabled$.pipe(
		map((scheduleDemoEnabled) => scheduleDemoEnabled),
	);

	constructor(
		private readonly store: Store<AppState>,
		private readonly memberFullNamePipe: MemberFullNamePipe,
	) { }

	async getEventParams(): Promise<{
		demoType: string;
		totalCompanyMembers: number;
	}> {
		const totalCompanyMembers = await this.store
			.select(selectCompanyMembers)
			.pipe(take(1))
			.toPromise();
		return {
			demoType: 'sidebar',
			totalCompanyMembers: Object.keys(totalCompanyMembers).length,
		};
	}

	async openCalendly(): Promise<void> {
		const user = await firstValueFrom(this.store.select(selectProfile).pipe(take(1)));
		const trade = await firstValueFrom(this.store
			.select(selectCompanyTrade)
			.pipe(filter<string>(Boolean), take(1)));
		const mobile = await firstValueFrom(this.store.select(selectUserMobile).pipe(take(1)));
		const companySize = await firstValueFrom(this.store
			.select(selectCompanySizeHumanize)
			.pipe(take(1)));

		Calendly.showPopupWidget(
			'https://calendly.com/craftnote/kostenfrei_online-demo_app',
			'PopupText',
			{
				prefill: {
					email: user.email,
					name: this.memberFullNamePipe.transform(user),
					customAnswers: {
						a1: trade,
						a2: companySize,
						a3: mobile,
					},
				},
			},
		);
	}
}
