import { AppState } from '../state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InviteState, Member, MemberRole, WorkType } from 'domain-entities';
import { AuthLifecycle, AuthState, UserRegistrationType } from '../state/auth.state';
import { ProjectStatusesState, WorkTypesState } from '../state/CompanySettings.state';
import { CompanyState } from '../state/CompanyState';
import { ApplicationVersionState } from '../state/application-version.state';
import { ProjectStatus, workTypeSelectors } from '../reducers/company-settings.reducer';
import { AppUiState } from '../state/app-ui.state';
import { Company } from '@shared/models/company.model';
import { keyBy, pickBy } from 'lodash';

export const selectAuthState = createFeatureSelector<AuthState>('authState');
export const selectProjectStatus = createFeatureSelector<ProjectStatusesState>('projectStatus');
export const selectWorkTypeState = createFeatureSelector<WorkTypesState>('workTypes');
export const selectVersionState = createFeatureSelector<ApplicationVersionState>('version');
export const selectAppUiState = createFeatureSelector<AppUiState>('appUiState');
export const selectCompanyState = createFeatureSelector<CompanyState>('companyState');

export const selectUserId = createSelector(selectAuthState, (authState) => {
	return authState.userId;
});

export const selectProfile = (state: AppState) => state.authState.user;

export const selectUserFullName = createSelector(selectProfile, (state) =>
	state ? `${state.lastname}, ${state.name}` : '',
);

export const selectUserEmail = createSelector(selectAuthState, (authState) =>
	authState.user ? authState.user.email : undefined,
);

export const selectUserMobile = createSelector(
	selectAuthState,
	(authState) => authState.user?.mobile,
);

export const selectUserRegistrationType = createSelector(
	selectAuthState,
	(authState) => authState.registrationType,
);

export const selectProfileLoadingStatus = createSelector(
	selectAuthState,
	(authState) => authState.loadingStatus,
);

export const selectCompanyId = createSelector(selectProfile, (userProfile) =>
	userProfile ? userProfile.company : undefined,
);

export const selectCompany = createSelector(
	selectCompanyState,
	(state: CompanyState) => state.company,
);

export const selectCompanyLoadingStatus = createSelector(
	selectCompanyState,
	(companyState) => companyState.loadingStatus,
);

export const selectDeserializedCompany = createSelector(selectCompany, (company) => {
	if (!company) {
		return company as null | undefined;
	}
	return new Company().deserialize(company);
});

export const selectCompanyMembers = createSelector(selectCompany, (company) => {
	if (!company) {
		return company as undefined | null;
	}
	return company.members;
});

export const selectCompanyMembersArray = createSelector(selectCompany, (company) => {
	if (!company) {
		return [];
	}
	return Object.values(company.members);
});

export const selectCompanyMembersAcceptedArray = createSelector(selectCompany, (company) => {
	if (!company) {
		return [];
	}
	return Object.values(company.members).filter(
		(member) => member.id && member.inviteState === InviteState.ACCEPTED,
	);
});

export const selectCompanyPhone = createSelector(selectCompany, (company) => {
	if (!company) {
		return company as undefined | null;
	}
	return company.phone;
});

export const selectCompanySize = createSelector(selectCompany, (company) => {
	if (!company) {
		return company as undefined | null;
	}
	return company.additionalInformation?.size;
});

export const selectCompanySizeHumanize = createSelector(selectCompanySize, (companySize) => {
	if (!companySize.to) {
		return `+${companySize.from}`;
	}
	return `${companySize.from} - ${companySize.to}`;
});

export const selectCompanyTrade = createSelector(selectCompany, (company) => {
	if (!company) {
		return company as undefined | null;
	}
	return company.trade;
});

export const selectProjectStatusEntities = createSelector(selectProjectStatus, (projectStatus) =>
	keyBy<ProjectStatus>(projectStatus, 'id'),
);

export const selectCompanyMembersAcceptedEntities = createSelector(
	selectCompanyMembers,
	(companyMembers) =>
		pickBy<Member>(companyMembers, (val) => val.inviteState === InviteState.ACCEPTED),
);

export const selectCompanyLimits = (state: AppState) => state.companyState.companyLimits;

export const selectCompanyChangeStatus = (state: AppState) =>
	state.companyState.companyChangeStatus;

export const selectUserRole = createSelector(
	selectCompany,
	selectUserEmail,
	(companyState, email) => {
		const companyMembers = companyState?.members || {};
		return companyMembers[email] && companyMembers[email].role;
	},
);

export const isOwnerOfCompany = createSelector(selectUserRole, (userRole) => {
	return userRole === MemberRole.OWNER;
});

export const isSupervisorOfCompany = createSelector(selectUserRole, (userRole) => {
	return userRole === MemberRole.SUPERVISOR;
});

export const isEmployeeOfCompany = createSelector(selectUserRole, (userRole) => {
	return userRole === MemberRole.EMPLOYEE;
});

export const isOwnerOrSupervisorOfCompany = createSelector(selectUserRole, (userRole) => {
	return userRole === MemberRole.OWNER || userRole === MemberRole.SUPERVISOR;
});

export const isNewerVersionAvailable = createSelector(
	selectVersionState,
	(appVersion) => appVersion && appVersion.isLatest,
);

export const selectIsLoggedIn = createSelector(
	selectAuthState,
	(authState) =>
		[UserRegistrationType.FRESH, UserRegistrationType.EXISTING].includes(
			authState.registrationType,
		) &&
		[AuthLifecycle.LOGGED_IN, AuthLifecycle.UNVERIFIED_LOGGED_IN].includes(authState.lifeCycle),
);

export const selectAuthLifecycle = createSelector(
	selectAuthState,
	(authState) => authState.lifeCycle,
);

const selectRouterFeature = (state: AppState) => state.router;
export const selectRouterState = createSelector(selectRouterFeature, (routerState) => routerState);

export const selectAllWorkTypeEntities = createSelector(
	selectWorkTypeState,
	workTypeSelectors.selectEntities,
);
export const selectAllWorkTypes = createSelector(selectWorkTypeState, workTypeSelectors.selectAll);

export const selectAllUndeletedWorkTypes = createSelector(
	selectAllWorkTypes,
	(workTypes: WorkType[]) => workTypes.filter((workType) => !workType.deleted),
);

export const selectIsSidebarEnabled = createSelector(
	selectAppUiState,
	(state) => state.isSidebarEnabled,
);

export const selectIsNavbarEnabled = createSelector(
	selectAppUiState,
	(state) => state.isNavbarEnabled,
);

export const selectTheme = createSelector(selectAppUiState, (state) => state.currentTheme);
