import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { selectCompanyId, selectUserEmail, selectUserId } from '@store/selectors/app.selectors';
import { BasicSnackbarComponent } from '@modules/shared/components/notification-snackbar/basic-snackbar/basic-snackbar.component';
import { NotificationSnackbarService } from '@injectables/services/notification-snackbar/notification-snackbar.service';
import { Auth, idToken } from '@angular/fire/auth';
import { selectPurchaseState } from '@injectables/services/purchase/purchase-state';

@Component({
	selector: 'app-debug-dialog',
	templateUrl: './debug-dialog.component.html',
	styleUrls: ['./debug-dialog.component.scss'],
})
export class DebugDialogComponent {
	@ViewChild('debugDialogRef') debugDialogRef: TemplateRef<any>;
	constructor(
		public readonly store: Store<AppState>,
		private readonly dialog: MatDialog,
		private readonly clipboard: Clipboard,
		private readonly notificationService: NotificationSnackbarService,
		private readonly auth: Auth,
	) { }

	companyId$ = this.store.select(selectCompanyId);
	userId$ = this.store.select(selectUserId);
	userEmail$ = this.store.select(selectUserEmail);


	subscriptionState$ = this.store.select(selectPurchaseState).pipe(
		map(response => {
			console.log('response', response);
			return {
				ids: response.purchaseId ? [response.purchaseId] : [],
				entities: response.purchaseId ? {
					[response.purchaseId]: {
						subscriptionId: response.purchaseId,
						hasRunningTrial: response.hasRunningTrial,
						remainingTrialDays: response.remainingTrialDays
					}
				} : {}
			}
		})
	);

	copyFields$ = combineLatest([
		this.companyId$,
		this.userId$,
		this.userEmail$,
		this.subscriptionState$,
		idToken(this.auth), // Include the token subject here
	]).pipe(
		map(([companyId, userId, userEmail, subscriptionState, token]) => {
			let subscriptionId = 'none';
			if (
				subscriptionState.ids.length > 0 &&
				subscriptionState.entities[subscriptionState.ids[0]].subscriptionId
			) {
				subscriptionId = subscriptionState.entities[subscriptionState.ids[0]].subscriptionId;
			}

			const fields = [
				{ label: 'Company Id', value: companyId },
				{ label: 'User ID', value: userId },
				{ label: 'User Email', value: userEmail },
				{
					label: 'Subscription Id',
					value: subscriptionId,
					linkTarget:
						subscriptionId === 'none'
							? undefined
							: 'https://dashboard.stripe.com/test/subscriptions/' + subscriptionId,
				},
			];

			if (token) {
				fields.push({ label: 'JWT Token', value: token });
			}

			return fields;
		}),
	);

	async open(): Promise<void> {
		await this.dialog
			.open(this.debugDialogRef, { maxHeight: '90vh' })
			.afterClosed()
			.pipe(take(1))
			.toPromise();
	}

	copyToClipboard(text: string): void {
		this.clipboard.copy(text.trim());

		this.notificationService.show(BasicSnackbarComponent, {
			componentTypes: {
				description: 'Copied to clipboard',
				icon: 'done',
			},
			level: 1,
			timeout: 5000,
		});
	}
}
