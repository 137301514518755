import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Environment, ENVIRONMENT } from '@shared/tokens/environment';
import { AuthService } from '@injectables/services/auth/auth.service';

export interface PurchaseState {
	purchaseId?: string;
	purchaseProductMatches?: PurchaseProductMatch[];
	hasRunningTrial?: boolean;
	remainingTrialDays?: number;
}

export interface PurchaseProductMatch {
	productId: string;
	purchaseId: string;
	productName: string;
	quantity: number;
}

@Injectable({
	providedIn: 'root'
})
export class PurchaseService {
	private readonly baseUrl: string;

	constructor(
		private readonly http: HttpClient,
		private readonly authService: AuthService,
		@Inject(ENVIRONMENT) private readonly environment: Environment
	) {
		this.baseUrl = this.environment.frontendComponents.middleLayer.purchaseWeb;
	}

	getPurchaseDetails(): Observable<PurchaseState> {
		return this.authService.authToken().pipe(
			filter(token => !!token),
			switchMap(token => {
				return this.http.get<PurchaseState>(`${this.baseUrl}/web-app-purchase`, {
					headers: {
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`
					}
				});
			}),
			map(response => {
				if (!response) {
					return {
						hasRunningTrial: false,
						purchaseProductMatches: []
					};
				}
				return response;
			})
		);
	}
}
